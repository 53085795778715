<template>
  <pro-login-layout @ChangeLanguage="(b) => (language = b)">
    <div class="login-form">
      <div class="form-item" v-if="step === 1">
        <span class="login-font text-primary">{{
          this.getRes("CustMenu.Login.TendererLogin")
        }}</span>

        <q-form class="login-form" @submit="checkSecurityCode">
          <q-input
            outlined
            v-model="info.securityCode"
            :label="getRes('Form.Col.SecurityCode')"
            style="margin-left: 0"
            lazy-rules
            :rules="[
              (val) =>
                (val && val.length > 0) ||
                getRes('Form.Message.Error.SecurityCode'),
            ]"
          />
          <q-field
            borderless
            dense
            bottom-slots
            :value="info.termsOfUse"
            :rules="[(val) => val || getRes('Form.Message.Error.AcceptTerms')]"
          >
            <q-checkbox
              v-model="info.termsOfUse"
              :label="getRes('Form.Option.Confirm')"
              class="text-dark"
              color="dark"
            />

            <template #error="{ errorMessage }">
              <div style="margin-left: 12px">{{ errorMessage }}</div>
            </template>
          </q-field>
          <q-btn
            no-caps
            color="primary"
            :label="getRes('System.Button.Next')"
            class="full-width"
            unelevated
            type="submit"
            :disable="isDisableCheckSecurityCode"
          />
          <div style="margin-top: 20px; margin-bottom: 20px">
            ({{ getRes("Form.Field.SecurityCodeDescribe") }})
          </div>
        </q-form>
      </div>

      <div class="form-item" v-if="step === 2">
        <span class="login-font text-primary">{{
          this.getRes("Form.Field.TwoStepVerification")
        }}</span>
        <div style="margin-top: 20px; margin-bottom: 20px">
          {{ getRes("Form.Field.TwoStepVerificationDescribe") }}
        </div>
        <q-card
          v-if="info.otpOption.email"
          class="my-card"
          unelevated
          @click="setTypeAndSendOtp('email')"
          style="cursor: pointer"
        >
          <q-card-section>
            <div class="row">
              <div class="col-2" style="text-align: center">
                <q-icon name="PROSmart-Email" style="font-size: 2.5em" />
              </div>
              <div class="col-10">
                {{ getRes("Form.Field.EmailAddressFormat") }} <br />
                <span style="overflow-wrap: break-word">{{
                  info.otpOption.email
                }}</span>
              </div>
            </div>
          </q-card-section>
        </q-card>
        <q-card
          v-if="info.otpOption.sms"
          class="my-card"
          style="margin-top: 10px; cursor: pointer"
          unelevated
          @click="setTypeAndSendOtp('sms')"
        >
          <q-card-section>
            <div class="row">
              <div class="col-2" style="text-align: center">
                <q-icon name="PROSmart-SMS" style="font-size: 2.5em" />
              </div>
              <div class="col-10">
                {{ getRes("Form.Field.TelephoneFormat") }} <br />
                {{ info.otpOption.sms }}
              </div>
            </div>
          </q-card-section>
        </q-card>
      </div>

      <div class="form-item" v-if="step === 3">
        <span class="login-font text-primary">{{
          this.getRes("Form.Field.TwoStepVerification")
        }}</span>
        <div style="margin-top: 20px; margin-bottom: 20px">
          {{
            getRes("Form.Field.SendVerificationCodeMessage", {
              verificationCodeMessage: verificationCodeMessage,
            })
          }}
        </div>
        <q-form @submit="login">
          <q-input
            outlined
            v-model="info.otpCode"
            :label="getRes('Form.Field.VerificationCode')"
            style="margin-left: 0"
            lazy-rules
            :rules="[
              (val) =>
                (val && val.length > 0) ||
                getRes('Form.Message.Error.VerificationCode'),
            ]"
          >
            <template v-slot:before> {{ prefix }} - </template>
          </q-input>
          <q-btn
            no-caps
            style="margin-top: 10px"
            color="primary"
            :label="getRes('System.Button.Submit')"
            class="full-width"
            unelevated
            type="submit"
            :disable="isDisableLogin"
          />
        </q-form>
        <q-btn
          no-caps
          style="margin-top: 10px"
          outline
          color="primary"
          class="full-width"
          :label="
            info.timeNum > 0
              ? `${getRes('Form.Field.ResendCode')} (${info.timeNum})`
              : `${getRes('Form.Field.ResendCode')}`
          "
          :disable="!info.enableResend"
          unelevated
          @click="sendOtp()"
        />
      </div>
    </div>

    <template #footer>
      <terms-of-use />
    </template>
  </pro-login-layout>
</template>

<script>
import TermsOfUse from "@/components/PROSmart/LoginPage/TermsOfUse";
import ProSmartLoginMixin from "@/views/mixins/ProSmartLogin";
import ProLoginLayout from "@/components/PROSmart/Layout/ProLoginLayout.vue";

export default {
  name: "TendererLogin",
  components: { ProLoginLayout, TermsOfUse },
  mixins: [ProSmartLoginMixin],
  data() {
    return {
      step: 1,
      resendTime: 60,
      info: {
        securityCode: "",
        termsOfUse: false,
        otpCode: "",
        prefix: "",
        otpOption: { email: "", sms: "" },
        verificationCodeMessage: "",
        selectType: "",
        timeNum: 0,
        enableResend: true,
        resendInterval: null,
      },
      language: "English",
      isDisableCheckSecurityCode: false,
      isDisableSendOtp: false,
      isDisableLogin: false,
    };
  },
  methods: {
    checkSecurityCode() {
      console.log(this.$proSmart.tendererLogin);
      this.isDisableCheckSecurityCode = true;
      this.$proSmart.tendererLogin
        .checkAccessCode(this, this.info.securityCode)
        .then((rs) => {
          if (rs.success !== undefined && rs.success === false) {
            let localeMessages = this.$t(rs.message);
            let moduleName = "";
            let combinedMessage = "";

            const entries = Object.entries(rs.messageInformation);
            for (let i = 0; i < entries.length; i++) {
              switch (entries[i][0]) {
                case "IssueDate":
                case "ClosingDate":
                  combinedMessage +=
                    "<tr><td style='width: 110px;'>" +
                    this.$t("CustMenu.Field." + entries[i][0]) +
                    ":</td>";
                  combinedMessage +=
                    "<td>" +
                    this.$proSmart.common.getFormattedDate(entries[i][1]) +
                    "</td></tr>";
                  break;
                case "ModuleName":
                  moduleName = entries[i][1];
                  break;
                default:
                  combinedMessage +=
                    "<tr><td style='width: 110px;'>" +
                    this.$t("CustMenu.Field." + entries[i][0]) +
                    ":</td>";
                  combinedMessage += "<td>" + entries[i][1] + "</td></tr>";
                  break;
              }
            }

            localeMessages += "<br><br>";
            localeMessages +=
              moduleName +
              " " +
              this.$t("System.Message.Error.TenderInformation") +
              "<br>";
            localeMessages += "<table style='border-spacing: 10px;'>";
            localeMessages += combinedMessage;
            localeMessages += "</table>";

            localeMessages = localeMessages.replace("[ModuleName]", moduleName);

            this.$alert(localeMessages, this.$t("notification"), {
              confirmButtonText: this.$t("ok"),
              dangerouslyUseHTMLString: true,
            });
            this.isDisableCheckSecurityCode = false;
            return;
          }
          this.info.otpOption = rs;
          this.step = 2;
        })
        .catch(() => (this.isDisableCheckSecurityCode = false));
    },
    updateResendTime() {
      this.info.timeNum--;
      if (this.info.timeNum <= 0) {
        this.info.enableResend = true;
        clearInterval(this.info.resendInterval);
      }
    },
    setTypeAndSendOtp(type) {
      if (this.isDisableSendOtp) return;
      this.isDisableSendOtp = true;
      this.selectType = type;
      this.sendOtp().catch(() => (this.isDisableSendOtp = false));
    },
    sendOtp() {
      this.info.enableResend = false;

      return this.$proSmart.tendererLogin
        .sendOtpCode(this, this.info.securityCode, this.selectType)
        .then((rs) => {
          if (rs.success) {
            this.prefix = rs.prefix;
            this.step = 3;
            this.verificationCodeMessage = `${this.selectType} ${
              this.info.otpOption[this.selectType]
            }`;

            this.info.timeNum = this.resendTime;
            this.info.resendInterval = setInterval(this.updateResendTime, 1000);
          }
        });
    },
    login() {
      this.isDisableLogin = true;
      this.$proSmart.tendererLogin
        .login(this, this.info.securityCode, this.info.otpCode.trim())
        .then((returnData) => {
          const success = this.loginHcm(returnData, this.language);
          if (success) this.$router.replace("/Dashboard");
        })
        .catch((msg) => {
          console.log(msg);
          this.isDisableLogin = false;
        });
    },
  },
  mounted() {
    localStorage.removeItem("userType");
    if (this.$route.query.code) this.info.securityCode = this.$route.query.code;
  },
};
</script>

<style scoped>
.login-wrapper .background-img {
  /* width: 51.2%; */
  z-index: -10;
  zoom: 1;
  min-height: 500px;
  background-size: 100% auto;
  background-repeat: no-repeat;
  position: relative;
  background-color: #0066b3;
  overflow: hidden;
  background-position: center;
}

.login-wrapper {
  display: -ms-flexbox;
  display: flex;
  height: 100vh;
}

.login-wrapper .content {
  /* width: 48.8%; */
  box-sizing: border-box;
  padding: 2.15% 1.6% 3% 4%;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}

.login-wrapper .content .main {
  margin-top: 10%;
  margin-left: 1%;
  min-width: 350px;
}

.login-wrapper .content .main .login-font {
  /*width: 97px;*/
  height: 44px;
  /*font-family: BeVietnam;*/
  font-size: 25px;
  font-weight: 600;
  margin: auto 0;
}

.login-wrapper .content .main .login-form .form-item .label-font {
  display: inline-block;
  margin-bottom: 6px;
  line-height: 23px;
  font-size: 16px;
  font-weight: 500;
  color: #212121;
}

.login-wrapper .content .main .login-form {
  margin-top: 25px;
  width: 100%;
}
</style>
