<template>
  <pro-login-layout @ChangeLanguage="(b) => (language = b)">
    <div class="login-form">
      <div class="form-item">
        <span class="login-font text-primary">{{
          getRes("CustMenu.Login.LoginSign")
        }}</span>

        <q-form
          class="login-form"
          @submit="login"
          v-if="showUsernameLogin"
          method="post"
        >
          <span class="label-font">
            {{ getRes("CustMenu.Login.UserName") }}
          </span>
          <q-input
            outlined
            v-model="info.userName"
            :placeholder="getRes('CustMenu.Login.UserName')"
            style="margin-left: 0"
          />

          <span class="label-font" style="margin-top: 16px">
            {{ getRes("CustMenu.Login.Password") }}
          </span>

          <q-input
            outlined
            type="password"
            v-model="info.password"
            :placeholder="getRes('CustMenu.Login.Password')"
            style="margin-left: 0"
            autocomplete="off"
          />

          <div class="loginForgotPassword">
            <router-link :to="{ path: '/ForgotPassword' }">
              {{ getRes("CustMenu.Login.ForgotPassword") }}
            </router-link>
          </div>
          <q-btn
            style="margin-top: 12px"
            no-caps
            color="primary"
            :label="getRes('CustMenu.Login.Login')"
            class="full-width"
            unelevated
            type="submit"
            :disable="isDisable"
          />
        </q-form>

        <sso-login-button
          :or-label="showUsernameLogin"
          :sso-name="['cicSaml']"
        ></sso-login-button>
      </div>
    </div>

    <template #footer>
      <DisclaimerAndCopyright />
    </template>
  </pro-login-layout>
</template>

<script>
import DisclaimerAndCopyright from "@/components/PROSmart/LoginPage/DisclaimerAndCopyright";
import ProSmartLoginMixin from "@/views/mixins/ProSmartLogin";
import ProLoginLayout from "@/components/PROSmart/Layout/ProLoginLayout.vue";
import SsoLoginButton from "../LoginPage/SsoLoginButton.vue";

export default {
  name: "ProcurerLogin",
  components: {
    SsoLoginButton,
    ProLoginLayout,
    DisclaimerAndCopyright,
  },
  mixins: [ProSmartLoginMixin],
  data() {
    return {
      info: {
        userName: "",
        password: "",
      },
      language: "English",
      isDisable: false,
      bIsUsedActiveDirectory: false,
      oADOptions: [],
      cSelectedAD: "",
      showSsoLogin: false,
      isSSOLogin: false,
      showUsernameLogin: false,
    };
  },
  methods: {
    login() {
      this.isDisable = true;

      if (this.info.userName === "" && this.info.password === "") {
        this.alert(
          this.getRes("login.userisemptynotify"),
          this.getRes("notification")
        );

        this.isDisable = false;
        return;
      }

      this.$proSmart.login
        .loginByUserCodePwd(
          this,
          this.info.userName,
          window.sha512(window.sha512(this.info.password)).toLowerCase()
        )
        .then((b) => {
          this.isDisable = false;

          if (!b.tokenId) {
            this.alert(b.info, this.getRes("notification"));
            return;
          }

          const success = this.loginHcm(b, this.language);
          if (success) this.$router.replace("/Dashboard");
        });
    },
    getLoginConfig() {
      this.$proSmart.setting.getCommonLoginSetting(this).then((rs) => {
        this.showUsernameLogin = rs.loginProcurerWithUsername;
      });
    },
  },
  mounted() {
    localStorage.setItem("userType", "procurer");
    if (this.$route.path !== "/procurerlogin") {
      this.$router.push({ path: "/procurerlogin" });
    }

    if (
      this.$route.query &&
      this.$route.query.action &&
      this.$route.query.action === "admin"
    ) {
      this.showUsernameLogin = true;
    } else {
      this.getLoginConfig();
    }
  },
};
</script>

<style scoped>
.login-wrapper .background-img {
  /* width: 51.2%; */
  z-index: -10;
  zoom: 1;
  min-height: 500px;
  background-size: 100% auto;
  background-repeat: no-repeat;
  position: relative;
  background-color: #0066b3;
  overflow: hidden;
  background-position: center;
}

.login-wrapper {
  display: -ms-flexbox;
  display: flex;
  height: 100vh;
}

.login-wrapper .content {
  /* width: 48.8%; */
  box-sizing: border-box;
  padding: 2.15% 1.6% 3% 4%;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}

.login-wrapper .content .main {
  margin-top: 10%;
  margin-left: 1%;
  min-width: 350px;
}

.login-wrapper .content .main .login-font {
  /*width: 97px;*/
  height: 44px;
  /*font-family: BeVietnam;*/
  font-size: 25px;
  font-weight: 600;
  margin: auto 0;
}

.login-wrapper .content .main .login-form .form-item .label-font {
  display: inline-block;
  margin-bottom: 6px;
  line-height: 23px;
  font-size: 16px;
  font-weight: 500;
  color: #212121;
}

.login-wrapper .content .main .login-form {
  margin-top: 25px;
  width: 100%;
}

.login-wrapper .content .main .loginForgotPassword {
  text-align: end;
  margin-top: 16px;
}

.sso-login {
  margin-top: 20px;
  border-top: 1px solid #dcdfe6;
}
</style>
