<template>
  <div class="hcm-form-page">
    <el-container class="hcm-form-page-container">
      <el-header class="title">
        <el-row class="title">
          <el-col :span="20" class="wfm-first-header-title">
            <h5 class="wf-common-title">{{ this.LocalUser.MenuPath }}</h5>
            <div class="division"></div>
          </el-col>
          <el-col :span="4">
            <closeThePage class="wf-closeThePage"></closeThePage>
          </el-col>
        </el-row>
      </el-header>
      <el-main class="hcm-form-page-main">
        <FormRender
          class="fb-form-container"
          ref="f"
          :data="data"
          :value="v"
          :request="request"
        ></FormRender>
      </el-main>
    </el-container>
  </div>
</template>

<script>
function isImage(fileName) {
  let imageSuffix = ["BMP", "GIF", "JPG", "JPEG", "PNG"];
  let reg = /\.(\w+)$/;
  let r = reg.exec(fileName);
  let fileSuffix = r && r[1];
  fileSuffix = fileSuffix && fileSuffix.toUpperCase();
  let isImage = fileSuffix ? imageSuffix.indexOf(fileSuffix) > -1 : false;
  return isImage;
}

export default {
  name: "hcm-page",
  data() {
    return {
      data: {},
      v: {},
      request: null,
    };
  },
  beforeDestroy() {
    this.data = {};
  },
  computed: {
    title() {
      try {
        return this.$route.meta.title;
      } catch {
        return "";
      }
    },
  },
  methods: {
    larkUploadFile(file) {
      return new Promise((resolve) => {
        var reader = new FileReader();
        var picName = file.file.name;
        reader.readAsDataURL(file.file);
        reader.onload = function () {
          var cFileContent = this.result;
          // 图片压缩 start
          let maxLength = 1024;
          if (cFileContent === "data:") {
            cFileContent += "text/plain;base64,IA==";
          }
          let hasResult =
            this.result && this.result.split(",")[0].match(/:(.*?);/);
          let mime = hasResult && hasResult[1];
          if (mime && mime.indexOf("image") > -1) {
            let image = new Image();
            image.src = this.result;
            image.onload = function () {
              if (this.width > maxLength || this.height > maxLength) {
                let ratio = this.height / this.width;
                let scale = maxLength / (ratio > 1 ? this.height : this.width);
                let canvas = document.createElement("canvas");
                canvas.height = this.height * scale;
                canvas.width = this.width * scale;
                let ctx = canvas.getContext("2d");
                ctx.clearRect(0, 0, canvas.width, canvas.height);
                ctx.drawImage(this, 0, 0, canvas.width, canvas.height);
                //压缩后的图片base64
                cFileContent = canvas.toDataURL(mime);
              }
              cFileContent = cFileContent.substring(
                cFileContent.indexOf("base64,") + 7
              );
              resolve({ content: cFileContent, name: picName });
            };
          } else {
            cFileContent = cFileContent.substring(
              cFileContent.indexOf("base64,") + 7
            );
            resolve({ content: cFileContent, name: picName });
          }
          // 图片压缩 end
        };
      }).then(({ content, name }) => {
        return new Promise((resolve, reject) => {
          this.invokeService(
            "CommonBinary",
            "UploadBase64Binary",
            [name, content, false],
            (msg) => {
              if (msg.ReturnData.$ != null) {
                let fileName = msg.ReturnData.$.FileName;
                let _isImage = isImage(fileName);
                //关键Key name, isImage, url
                resolve({
                  fileId: msg.ReturnData.$.ID,
                  fileName: fileName,
                  fileUrl: "",
                  name: fileName,
                  isImage: _isImage,
                  url: `${window.Lark.ServiceAjax.url}/Handlers/CommonBinary.ashx?dataID=${msg.ReturnData.$.ID}&download=true`,
                });
              } else {
                reject("上传失败 CommonBinary.UploadBase64Binary 结果为false");
              }
            },
            reject
          );
        });
      });
    },
    getData() {
      return this.$refs.f
        .getFinallyModels()
        .then((data) => {
          let { attachment } = data;
          if (attachment) {
            let file = attachment.length
              ? new window.Lark.Resource({
                  ID: attachment[0].fileId,
                  FileName: attachment[0].fileName,
                })
              : null;
            var obj = { ...data, attachment: file };
            return Promise.resolve(obj);
          } else {
            return Promise.resolve(data);
          }
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    validate() {
      return new Promise((resolve) => {
        this.$refs.f.validate(resolve);
      });
    },
    loadFormDefinition() {
      if (!this.$route.meta || !this.$route.meta.formCode) {
        console.error("Invalid form code");
        return Promise.reject("Invalid form code");
      }

      const formCode = this.$route.meta.formCode;

      return new Promise((resolve, reject) => {
        this.invokeService(
          "FormRuntime",
          "GetFormDefinitionByCode",
          [formCode, ""],
          resolve,
          reject
        );
      });
    },
  },
  mounted() {
    this.loadFormDefinition().then((resp) => {
      if (resp.ReturnData.$) {
        this.data = JSON.parse(resp.ReturnData.formDefinition || "{}");

        if (this.data) {
          this.request = { lark: this.invokeService.bind(this) };
          this.v.LocalUser = this.LocalUser;
          this.$refs.f.formCreate();
        }
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.hcm-form-page {
  display: flex;
  height: 100%;
  width: 100%;
  overflow: hidden;
  .hcm-form-page-container {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    .title {
      margin-bottom: 0;
      margin-top: 10px;
    }
    .hcm-form-page-main {
      height: 100%;
      flex-direction: column;
      display: flex;
      box-sizing: border-box;
    }
  }
}
</style>
