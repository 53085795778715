export default {
  path: "Document/BackupBuyerSearch", // Url Path
  name: "DocumentBackupBuyerSearch", // Route name
  parent: "Home1", // Parent route path
  component: () => import("./DocumentSearch.vue"),
  props: { backupBuyerMode: true },
  title: "menu.DocumentBackupBuyerSearch", // Form title, locale resource key, it SHOULD be same as menu rkey
  sequence: 99, // No use
  portalName: "procurer",
};
