<template>
  <FormRender
    class="fb-form-container"
    ref="f"
    :data="data"
    :value="v"
    :request="request"
    :fileUploader="larkUploadFile"
    @showSubmitButton="showSubmitButton"
  ></FormRender>
</template>

<script>
import Vue from "vue";
import { getLanguage } from "@/util";
import formStepperActionMixin from "@/mixins/formStepperActionMixin";

export default {
  name: "form-container",
  props: {
    readOnly: Boolean,
  },
  mixins: [formStepperActionMixin],
  data() {
    return {
      data: {},
      v: {},
      text: "",
      request: null,
      isLoad: true,
      fileListModels: [],
      formName: "",
      globalResources: "",
    };
  },
  beforeDestroy() {
    this.data = {};
  },
  created() {
    this._ebus = new Vue();
  },
  methods: {
    getFormRender() {
      return this.$refs.f;
    },
    larkUploadFile(file) {
      return new Promise((resolve) => {
        var reader = new FileReader();
        var picName = file.file.name;
        reader.readAsDataURL(file.file);
        reader.onload = function () {
          var cFileContent = this.result;
          // 图片压缩 start
          let maxLength = 1024;
          if (cFileContent === "data:") {
            cFileContent += "text/plain;base64,IA==";
          }
          let hasResult =
            this.result && this.result.split(",")[0].match(/:(.*?);/);
          let mime = hasResult && hasResult[1];
          if (mime && mime.indexOf("image") > -1) {
            let image = new Image();
            image.src = this.result;
            image.onload = function () {
              if (this.width > maxLength || this.height > maxLength) {
                let ratio = this.height / this.width;
                let scale = maxLength / (ratio > 1 ? this.height : this.width);
                let canvas = document.createElement("canvas");
                canvas.height = this.height * scale;
                canvas.width = this.width * scale;
                let ctx = canvas.getContext("2d");
                ctx.clearRect(0, 0, canvas.width, canvas.height);
                ctx.drawImage(this, 0, 0, canvas.width, canvas.height);
                //压缩后的图片base64
                cFileContent = canvas.toDataURL(mime);
              }
              cFileContent = cFileContent.substring(
                cFileContent.indexOf("base64,") + 7
              );
              resolve({ content: cFileContent, name: picName });
            };
          } else {
            cFileContent = cFileContent.substring(
              cFileContent.indexOf("base64,") + 7
            );
            resolve({ content: cFileContent, name: picName });
          }
          // 图片压缩 end
        };
      }).then(({ content, name }) => {
        return new Promise((resolve, reject) => {
          this.invokeService(
            "CommonBinary",
            "UploadBase64Binary",
            [name, content, false],
            (msg) => {
              if (msg.ReturnData.$ != null) {
                let imageSuffix = ["BMP", "GIF", "JPG", "JPEG", "PNG"];
                let fileName = msg.ReturnData.$.FileName;
                let reg = /\.(\w+)$/;
                let r = reg.exec(fileName);
                let fileSuffix = r && r[1];
                fileSuffix = fileSuffix && fileSuffix.toUpperCase();
                let isImage = fileSuffix
                  ? imageSuffix.indexOf(fileSuffix) > -1
                  : false;
                //关键Key name, isImage, url
                resolve({
                  fileId: msg.ReturnData.$.ID,
                  fileName: fileName,
                  fileUrl: "",
                  name: fileName,
                  isImage,
                  url: `${window.Lark.ServiceAjax.url}/Handlers/CommonBinary.ashx?dataID=${msg.ReturnData.$.ID}&download=true`,
                });
              } else {
                reject("上传失败 CommonBinary.UploadBase64Binary 结果为false");
              }
            },
            reject
          );
        });
      });
    },
    getData() {
      return this.getFormRender()
        .getFinallyModels()
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    getContextModels() {
      return this.getFormRender().getFullContextModels();
    },
    getFormName() {
      return this.formName;
    },
    _setParameters(params) {
      this.getFormRender().setModels(params);
      this.setState();
    },

    setParameters(params) {
      this.v = { ...this.v, ...params };
      if (!this.getFormRender().context) {
        this._ebus.$once("form-created", () => {
          this._setParameters(this.v);
        });
      } else {
        this._setParameters(this.v);
      }
    },

    setState() {
      if (this.readOnly) {
        let widgetDict = this.getFormRender().context.widgetDict;
        for (let WID in widgetDict) {
          widgetDict[WID].options.disabled = true;
          widgetDict[WID].options.readonly = true;
        }
      }
    },
    validate() {
      return new Promise((resolve) => {
        if (!this._formCreated) {
          this.init().then(() => {
            this.getFormRender()
              .uploadFile()
              .then(() => {
                this.getFormRender().validate(resolve);
              });
          });
        } else {
          this.getFormRender()
            .uploadFile()
            .then(() => {
              this.getFormRender().validate(resolve);
            });
        }
      });
    },
    loadFormDefinition() {
      const processId = this.ProcessInfo.get("processId");
      const activityId = this.ProcessInfo.get("activityId");
      const processModelId = this.ProcessInfo.get("processModelId");
      const userId = this.LocalUser.get("UserId");

      if (processId && activityId) {
        return new Promise((resolve, reject) => {
          this.invokeService(
            "FormRuntime",
            "GetApprovalForm",
            [processId, activityId, userId, "", ""],
            resolve,
            reject
          );
        });
      } else if (processModelId) {
        return new Promise((resolve, reject) => {
          this.invokeService(
            "FormRuntime",
            "GetStartForm",
            [processModelId, userId, "", ""],
            resolve,
            reject
          );
        });
      } else {
        return Promise.reject();
      }
    },
    async init() {
      if (this._formCreated) return Promise.resolve();

      let formRender = this.getFormRender();
      this.globalResources = await formRender.loadGlobalResources();

      return this.loadFormDefinition().then((resp) => {
        if (resp.ReturnData.$) {
          this.data = JSON.parse(resp.ReturnData.formDefinition || "{}");

          if (this.data) {
            console.log(
              "%c%s Form Code",
              "color: #c16718;font-weight: bold;",
              "[ProSmart]",
              this.data.$code
            );

            this.request = { lark: this.invokeService.bind(this) };
            this.v.LocalUser = this.LocalUser;
            this.v.ProcessInfo = this.ProcessInfo;
            formRender.setLanguage(getLanguage());
            formRender.setGlobalResources(this.globalResources);
            return formRender.formCreate().then(() => {
              // console.log(getLanguage());
              // console.log(this, this.getFormRender()._i18n._vm._data.messages["en-US"]);
              this._ebus.$emit("form-created");
              this._formCreated = true;

              this.$emit("formCreated", this.updateStepper());
            });
          } else {
            return Promise.reject();
          }
        } else {
          return Promise.reject();
        }
      });
    },
    showSubmitButton(isShow) {
      this.$emit("showSubmitButton", isShow);
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="scss">
.fb-form-container {
  flex: auto;
  overflow: auto;
  padding: 10px;

  input:disabled {
    background-color: rgba(0, 0, 0, 0);
  }
}
</style>
