<script>
export default {
  name: "hcm-welcome-widget",
  computed: {
    userName() {
      let lang = localStorage.getItem("Language");

      if (lang === "English") {
        return this.LocalUser.EName;
      }

      if (lang === "Chinese") {
        return this.LocalUser.CName === ""
          ? this.LocalUser.EName
          : this.LocalUser.CName;
      }

      return this.LocalUser.TName === ""
        ? this.LocalUser.EName
        : this.LocalUser.TName;

      // console.log(
      //   "%c%s test;",
      //   "color: #a50aa5;font-weight: bold;",
      //   "[Andy Debug]",
      //   this
      // );
      //
      // console.log(
      //   "%c%s this.LocalUser",
      //   "color: #a50aa5;font-weight: bold;",
      //   "[Andy Debug]",
      //   this.LocalUser
      // );
    },
    loginUserName() {
      if (this.LocalUser.UserId === this.LocalUser.LoginUserId) {
        return "";
      }

      let userName = "";
      let lang = localStorage.getItem("Language");

      if (lang === "English") {
        userName = this.LocalUser.LoginEName;
      }

      if (lang === "Chinese") {
        userName =
          this.LocalUser.LoginCName === ""
            ? this.LocalUser.LoginEName
            : this.LocalUser.LoginCName;
      }

      userName =
        this.LocalUser.LoginTName === ""
          ? this.LocalUser.LoginEName
          : this.LocalUser.LoginTName;

      return `(${this.$t("CustMenu.Field.DelegatedLoginBy")} ${userName})`;
    },
    lastLoginDate() {
      const lastLogin = Number(localStorage.getItem("LastLoginTime"));
      if (lastLogin > 0) {
        const date = new Date(lastLogin);
        return this.$proSmart.common.getFormattedDate(date);
      } else {
        return "";
      }
    },
  },
  render() {
    return (
      <div class="widget no-header">
        <div class="welcome-msg">
          <h1 class="welcome-msg-title">
            {this.$t("welcome")}&nbsp;
            <span style="word-break: break-all;">{this.userName}!</span>
            <span style="word-break: break-all;font-size: 20px;margin-left: 16px;">
              {this.loginUserName}
            </span>
            {this.lastLoginDate ? (
              <span class="welcome-msg-subtitle">{`${this.getRes(
                "LastLogin"
              )} ${this.lastLoginDate}`}</span>
            ) : (
              ""
            )}
          </h1>
        </div>
      </div>
    );
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/quasar.variables";

.welcome-msg {
  padding: 0;

  &-title {
    font-size: 29px;
    padding: 3px 0;
    line-height: 35px;
    color: $primary;
    margin-bottom: 0;
  }

  &-subtitle {
    font-size: 16px;
    margin-left: 15px;
    color: $proGrey;
  }
}
</style>
