<template>
  <div>
    <div
      v-if="
        orLabel &&
        showSsoLogin &&
        externalData.some((b) => ssoName.includes(b.Name))
      "
      class="separator"
    >
      {{ getRes("CustMenu.Message.or") }}
    </div>

    <div v-if="showSsoLogin">
      <div v-for="e in externalData || []" :key="e.Name">
        <q-btn
          v-if="ssoName.includes(e.Name)"
          class="full-width justify-center"
          :style="{ background: config[e.Name].color }"
          align="between"
          no-caps
          text-color="white"
          @click="externalBtnClick(e.AuthPath, e.AuthenticationType, e.Name)"
        >
          <q-icon
            style="position: absolute; top: 6px; left: 10px"
            :name="config[e.Name].icon"
          />
          <div class="text-center full-width">
            {{ getRes(config[e.Name].name) }}
          </div>
        </q-btn>
      </div>
    </div>
  </div>
</template>

<script>
import ssoConfig from "../../../config/sso.json";

export default {
  name: "SsoLoginButton",
  props: {
    orLabel: {
      type: Boolean,
      default: false,
    },
    ssoName: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showSsoLogin: false,
      externalData: JSON.parse(localStorage.getItem("SSOLoginServerData")),
      ssoBasePath: localStorage.getItem("SSOLoginServer"),
      config: ssoConfig,
    };
  },
  mounted() {
    //测试链接 获取域控制设置
    this.invokeService(
      "Test",
      "TestConnection",
      [],
      (msg) => {
        if (msg.ReturnData.$.Success) {
          localStorage.setItem(
            "IsUsedActiveDirectory",
            msg.ReturnData.$.IsUsedActiveDirectory
          );
          this.bIsUsedActiveDirectory = msg.ReturnData.$.IsUsedActiveDirectory;

          // if use ad login, get the domain controller list
          if (this.bIsUsedActiveDirectory) {
            const loList = msg.ReturnData.$.DomainController.toJson();
            let lnDCList = 0;

            for (lnDCList = 0; lnDCList < loList.length; lnDCList++) {
              let lcLabel = "";

              if (this.language == "Chinese") {
                lcLabel = loList[lnDCList].c_name;
              } else if (this.language == "English") {
                lcLabel = loList[lnDCList].e_name;
              } else {
                lcLabel = loList[lnDCList].t_name;
              }

              if (this.cSelectedAD == "") {
                this.cSelectedAD = loList[lnDCList].domainname;
              }

              if (loList[lnDCList].isdefault) {
                this.cSelectedAD = loList[lnDCList].domainname;
              }

              this.oADOptions.push({
                label: lcLabel,
                value: loList[lnDCList].domainname,
              });
            }
          }
          localStorage.setItem(
            "UILockIntervalMinutes",
            msg.ReturnData.$.UILockIntervalMinutes
          );
          localStorage.setItem(
            "SMSForgotPasswordEnable",
            msg.ReturnData.$.SMSForgotPasswordEnable
          );
          if (msg.ReturnData.$.SSOLoginServer != undefined) {
            this.ssoBasePath = msg.ReturnData.$.SSOLoginServer;
            localStorage.setItem("SSOLoginServer", this.ssoBasePath);

            window.axios
              .get(
                msg.ReturnData.$.SSOLoginServer +
                  "/api/Configuration/GetAllConfigurations"
              )
              .then(({ data }) => {
                this.externalData = data;
                localStorage.setItem(
                  "SSOLoginServerData",
                  JSON.stringify(this.externalData)
                );

                this.showSsoLogin = true;
              })
              .catch(() => {});
          } else {
            window.localStorage.removeItem("SSOLoginServerData");
            this.showSsoLogin = false;
          }
        }
      },
      () => {},
      this
    );
  },
  methods: {
    externalBtnClick(authPath, type, authType) {
      const protocol = window.location.protocol;
      const host = window.location.host;
      let pathname = window.location.pathname;
      let index = pathname.toLowerCase().indexOf("index.html");
      if (index > 0) {
        pathname = pathname.toLowerCase().substring(0, index);
      }
      window.location.href =
        this.ssoBasePath +
        authPath +
        "/" +
        authType +
        "?client_id=WFMPC&redirect_uri=" +
        protocol +
        "//" +
        host +
        pathname +
        "static/oauthcallbackpage.html&state=" +
        type +
        "&scope=id&response_type=token";
    },
  },
};
</script>

<style scoped>
.separator {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  text-align: center;
}

.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #000;
}

.separator:not(:empty)::before {
  margin-right: 0.25em;
}

.separator:not(:empty)::after {
  margin-left: 0.25em;
}
</style>
