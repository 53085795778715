import { getLanguage, load } from "@/util";
import { routes } from "@/router";
import SupplierLogin from "@/components/PROSmart/Page/SupplierLogin";
import TendererLogin from "@/components/PROSmart/Page/TendererLogin";
import ProcurerLogin from "@/components/PROSmart/Page/ProcurerLogin";
import DisclaimerPage from "@/components/PROSmart/Page/DisclaimerPage";
import FormContainer from "@/components/FormContainer";
import OpenTenderList from "@/components/OpenTenderList";
import ProUpcomingMeetingList from "@/components/PROSmart/DashboardComponent/ProUpcomingMeetingList";
import EmailAction from "@/components/PROSmart/Page/EmailAction";
// import Chinese from "@/resource/Chinese";
// import English from "@/resource/English";
// import TraditionalChinese from "@/resource/TraditionalChinese";
import i18n from "@/i18n";
import { importPROSmartIcon, importPROSmartIconCss } from "@/proSmartIcon";
import { install as installWidgets } from "@/dashboard-widgets";

import afterLogin from "./events/afterLogin";

function loadDocumentInToWhiteList(vm) {
  vm.addRouteToWhiteList({ name: "Document" });

  const viewFiles = require.context(
    "@/views/modules/document",
    true,
    /.*\.page\.js$/
  );

  /** @type {Array<{component: Function, name: string, path: string, portalName:string, title:string}>} **/
  let routerConfigList = viewFiles.keys().map((k) => viewFiles(k).default);

  for (let key in viewFiles.keys().map((k) => viewFiles(k).default)) {
    let routerConfig = routerConfigList[key];

    vm.addRouteToWhiteList({ name: routerConfig.name });
  }
}

export function init() {
  return load("form-designer/formDesigner.umd.min.js")
    .then(() => {
      window.bootstrap({
        beforeInit({ registerRedirection, registerMenu, importRes }) {
          importRes({
            Chinese: { config: i18n.messages["zh-Hans"] },
            English: { config: i18n.messages["en-US"] },
            TraditionalChinese: { config: i18n.messages["zh-Hant"] },
          });

          registerMenu({
            path: "/MobilePreview",
            name: "MobilePreview",
            component: window.formDesigner.MobilePreview,
          });

          registerMenu({
            path: "/TendererLogin",
            name: "TendererLogin",
            component: TendererLogin,
          });

          registerMenu({
            path: "/SupplierLogin",
            name: "SupplierLogin",
            component: SupplierLogin,
          });

          registerRedirection("Login", { name: "SupplierLogin" });
        },
        onInit() {
          window.formDesigner.i18n.mergeLocaleMessage(
            "en-US",
            i18n.messages["en-US"]
          );

          window.formDesigner.i18n.mergeLocaleMessage(
            "zh-Hans",
            i18n.messages["zh-Hans"]
          );

          window.formDesigner.i18n.mergeLocaleMessage(
            "zh-Hant",
            i18n.messages["zh-Hant"]
          );

          window.createVue({ i18n: window.formDesigner.i18n });
        },
        afterInit() {
          window.formDesigner.loadAddons(window.formDesigner.i18n);
          window.formDesigner.loadTheme();
          const vm = window.appVue;

          if (!vm.Events.LoginEvents) {
            vm.Events.LoginEvents = {};
          }

          vm.Events.LoginEvents.afterSSOLogin = function () {
            let setting = afterLogin.getAfterSSOLoginSetting();

            if (localStorage.getItem("userType") === "procurer") {
              if (!!this.CustomPathMap && !!this.CustomPathMap.login) {
                this.CustomPathMap.login = "/procurerlogin";
              }
            }

            if (setting) {
              afterLogin.processAction.call(this, setting.action, {
                id: setting.tenderId,
                ref: setting.tenderRef,
              });
            } else {
              afterLogin.processAction.call(this);
            }
          };

          // function formatMessage(messages) {
          //   return Object.keys(messages).reduce(
          //     (acc, curr) => (
          //       (acc[curr.replace(/\./g, "_")] = messages[curr]), acc
          //     ),
          //     {}
          //   );
          // }
          // window.formDesigner.i18n.mergeLocaleMessage("en-US", {
          //   hcm: formatMessage(vm.English.config),
          // });
          // window.formDesigner.i18n.mergeLocaleMessage("zh-Hans", {
          //   hcm: formatMessage(vm.Chinese.config),
          // });
          // window.formDesigner.i18n.mergeLocaleMessage("zh-Hant", {
          //   hcm: formatMessage(vm.TraditionalChinese.config),
          // });

          window.formDesigner.i18n.mergeLocaleMessage(
            "en-US",
            vm.English.config
          );
          window.formDesigner.i18n.mergeLocaleMessage(
            "zh-Hans",
            vm.Chinese.config
          );
          window.formDesigner.i18n.mergeLocaleMessage(
            "zh-Hant",
            vm.TraditionalChinese.config
          );

          vm.registerCmp("FormRender", window.formDesigner.FormRender);
          vm.registerCmp("CodeEditor", window.formDesigner.CodeEditor);
          vm.registerCmp("FormContainer", FormContainer);
          vm.registerCmp("OpenTenderList", OpenTenderList);
          vm.registerCmp("ProUpcomingMeetingList", ProUpcomingMeetingList);

          // appVue.addRouteToWhiteList('/FormDesigner');
          // appVue.registerMenu({
          //   path: "/FormDesigner",
          //   name: "FormDesigner",
          //   component: FormDesigner
          // }, "Home");

          vm.addRouteToWhiteList("/SupplierLogin");

          vm.addRouteToWhiteList("/MobilePreview");
          vm.registerMenu({
            path: "/MobilePreview",
            name: "MobilePreview",
            component: window.formDesigner.MobilePreview,
          });

          vm.addRouteToWhiteList({ name: "TendererLogin" });
          vm.registerMenu({
            path: "/TendererLogin",
            name: "TendererLogin",
            component: TendererLogin,
          });

          vm.addRouteToWhiteList({ name: "ProcurerLogin" });
          vm.registerMenu({
            path: "/ProcurerLogin",
            name: "ProcurerLogin",
            component: ProcurerLogin,
          });

          vm.registerMenu({
            path: "/Action/:emailUid/:securityCode/:action",
            name: "EmailAction",
            component: EmailAction,
          });
          vm.addRouteToWhiteList({ name: "EmailAction" });

          vm.addRouteToWhiteList("/Disclaimer");
          vm.registerMenu({
            path: "/Disclaimer",
            name: "DisclaimerPage",
            component: DisclaimerPage,
          });

          vm.addRouteToWhiteList("/dashboard");
          loadDocumentInToWhiteList(vm);

          // appVue.addRouteToWhiteList('/SupplierRegistration/Login');
          // appVue.registerMenu({
          //   path: "/SupplierRegistration/Login",
          //   name: "SupplierRegistrationLogin",
          //   component: SupplierRegistrationLogin,
          // });

          // appVue.addRouteToWhiteList('/SupplierRegistration/Form');
          // appVue.registerMenu({
          //   path: "/SupplierRegistration/Form",
          //   name: "SupplierRegistrationForm",
          //   component: SupplierRegistrationForm,
          // });

          const viewFiles = require.context(
            "@/components/PROSmart/Page",
            true,
            /index\.js$/
          );
          const viewRoutes = viewFiles
            .keys()
            .map((k) => viewFiles(k).default)
            .sort((a, b) => a.sequence - b.sequence)
            .filter((rs) => {
              if (!rs.portalName) return false;
              let portalList = rs.portalName.split(",");

              if (portalList.length < 1) return false;
              return portalList.includes(process.env.VUE_APP_BUILD_PORTAL);
            })
            .map((module) => ({
              path: "/" + (module.path || module.name),
              name: module.name,
              component: module.component,
              children: module.children || [],
              meta: {
                title: module.title,
                sequence: module.sequence,
              },
            }));

          viewRoutes.forEach((route) => {
            vm.registerMenu(route);
            vm.addRouteToWhiteList(route.path);
            route.children.forEach((child) => {
              vm.addRouteToWhiteList(`${route.path}/${child.path}`);
            });
          });

          //#region [ Register Custom HCM Resources ]
          // vm.importRes({
          //   Chinese: Chinese,
          //   English: English,
          //   TraditionalChinese: TraditionalChinese,
          // });
          //#endregion

          i18n.locale = getLanguage();

          importPROSmartIcon(vm);
          importPROSmartIconCss();
          installWidgets(vm.registerCmp);

          // vm.Events.LoginEvents.afterSSOLogin = () => {
          //   if (localStorage.getItem("userType") === "procurer") {
          //     console.log("this", this);
          //     console.log("this.CustomPathMap", this.CustomPathMap);
          //
          //     // this.CustomPathMap.login = "/procurerlogin";
          //     // localStorage.removeItem("userType");
          //   }
          // };

          return new Promise((resolve) => {
            vm.$nextTick(() => {
              routes.forEach((r) => {
                const { parent, ...route } = r;
                vm.registerMenu(route, parent);
              });

              vm.__proto__.registerFn = (name, fn) => {
                vm.__proto__[name] = fn;
              };

              resolve();
            });
          });
        },
      });
    })
    .catch((err) => console.error(err));
}
