import WelcomeWidget from "./welcome";
import WorkBenchWidget from "./workbench";
import QuickAccess from "./quickAccess";
import QuickAccessSetting from "./quickAccessSetting";

export function install(register) {
  register(WelcomeWidget.name, WelcomeWidget);
  register(WorkBenchWidget.name, WorkBenchWidget);
  register(QuickAccess.name, QuickAccess);
  register(QuickAccessSetting.name, QuickAccessSetting);
}
