import Vue from "vue";
window.Vue = Vue;

import i18n from "./i18n";
window.VueI18n = i18n;

import { installQuasar } from "./quasar";
installQuasar(window.Vue);

import axios from "axios";
window.axios = axios;

import ElementUI from "element-ui";

import en from "element-ui/lib/locale/lang/en";
import cn from "element-ui/lib/locale/lang/zh-CN";
import tw from "element-ui/lib/locale/lang/zh-TW";

import locale from "element-ui/lib/locale";

let lang = localStorage.getItem("Language");

switch (lang) {
  case "English":
    locale.use(en);
    break;
  case "TraditionalChinese":
    locale.use(tw);
    break;
  case "Chinese":
    locale.use(cn);
    break;
  default:
    locale.use(en);
    break;
}

window.ElementUI = ElementUI;
window.ELEMENT = ElementUI;

import { installPROSmart } from "./proSmart";
installPROSmart(window.Vue);

import { installVCalendar } from "./vCalendar";
installVCalendar(window.Vue);

// import FormDesigner from "@/views/form-designer";
// import FormContainer from "@/components/FormContainer";
// import OpenTenderList from "@/components/OpenTenderList";
// import ProUpcomingMeetingList from "@/components/PROSmart/DashboardComponent/ProUpcomingMeetingList";
//
// import { install as installWidgets } from "@/dashboard-widgets";

//#region [ For debug ]
// const originalVueUse = Vue.use;
// Vue.use = function (plugin) {
//   if (!plugin) {
//     debugger;
//   }

//   return originalVueUse.call(this, plugin);
// };
//#endregion

import { load, compose } from "@/util";
import { init } from "@/init";

// import { load, compose } from "@/util";
// compose(
//   () => load("static/js/manifest.js"),
//   () => load("static/js/vendor.js"),
//   () => load("static/js/app.js")
// ).then(

// );

//#region [ Custom HCM Resources Importing ]
// import Chinese from "@/resource/Chinese";
// import English from "@/resource/English";
// import TraditionalChinese from "@/resource/TraditionalChinese";
//#endregion

// import { importPROSmartIcon } from "./proSmartIcon";

import "@/styles/global.scss";
import "@/styles/PROSmart.scss";
// import TendererLogin from "@/components/PROSmart/Page/TendererLogin.vue";
// import EmailAction from "@/components/PROSmart/Page/EmailAction";
// import SupplierRegistrationLogin from "@/components/PROSmart/Page/SupplierRegistrationLogin";
// import SupplierRegistrationForm from "@/components/PROSmart/Page/SupplierRegistrationForm";

compose(
  () => load("static/js/manifest.js"),
  () => load("static/js/vendor.js"),
  () => load("static/js/app.js")
).then(() => {
  //#region [ Initialization ]
  init();

  //   .then((appVue) => {
  //   console.log(
  //     "%c%s mainjs appVue",
  //     "color: #a50aa5;font-weight: bold;",
  //     "[Andy Debug]",
  //     appVue
  //   );
  //
  //   return;
  //   /*
  //       appVue.addRouteToWhiteList 是为了绕过后台跳转路由,demo包里的测试api
  //       实际版本没有此项,路由需要后台注册
  //     */
  //   appVue.registerCmp("FormRender", window.formDesigner.FormRender);
  //   appVue.registerCmp("FormContainer", FormContainer);
  //   appVue.registerCmp("OpenTenderList", OpenTenderList);
  //   appVue.registerCmp("ProUpcomingMeetingList", ProUpcomingMeetingList);
  //
  //   // appVue.addRouteToWhiteList("/FormDesigner");
  //   // appVue.registerMenu({
  //   //   path: "/FormDesigner",
  //   //   name: "FormDesigner",
  //   //   component: FormDesigner
  //   // }, "Home");
  //
  //   appVue.addRouteToWhiteList("/MobilePreview");
  //   appVue.registerMenu({
  //     path: "/MobilePreview",
  //     name: "MobilePreview",
  //     component: window.formDesigner.MobilePreview,
  //   });
  //
  //   appVue.addRouteToWhiteList("/TendererLogin");
  //   appVue.registerMenu({
  //     path: "/TendererLogin",
  //     name: "TendererLogin",
  //     component: TendererLogin,
  //   });
  //
  //   appVue.addRouteToWhiteList("/Action");
  //   appVue.registerMenu({
  //     path: "/Action/:emailUid/:securityCode/:action",
  //     name: "EmailAction",
  //     component: EmailAction,
  //   });
  //
  //   // appVue.addRouteToWhiteList("/SupplierRegistration/Login");
  //   // appVue.registerMenu({
  //   //   path: "/SupplierRegistration/Login",
  //   //   name: "SupplierRegistrationLogin",
  //   //   component: SupplierRegistrationLogin,
  //   // });
  //
  //   // appVue.addRouteToWhiteList("/SupplierRegistration/Form");
  //   // appVue.registerMenu({
  //   //   path: "/SupplierRegistration/Form",
  //   //   name: "SupplierRegistrationForm",
  //   //   component: SupplierRegistrationForm,
  //   // });
  //
  //   const viewFiles = require.context(
  //     "@/components/PROSmart/Page",
  //     true,
  //     /index\.js$/
  //   );
  //   const viewRoutes = viewFiles
  //     .keys()
  //     .map((k) => viewFiles(k).default)
  //     .sort((a, b) => a.sequence - b.sequence)
  //     .filter((rs) => {
  //       if (!rs.portalName) return false;
  //       let portalList = rs.portalName.split(",");
  //
  //       if (portalList.length < 1) return false;
  //       return portalList.includes(process.env.VUE_APP_BUILD_PORTAL);
  //     })
  //     .map((module) => ({
  //       path: "/" + (module.path || module.name),
  //       name: module.name,
  //       component: module.component,
  //       children: module.children || [],
  //       meta: {
  //         title: module.title,
  //         sequence: module.sequence,
  //       },
  //     }));
  //
  //   viewRoutes.forEach((route) => {
  //     appVue.registerMenu(route);
  //     appVue.addRouteToWhiteList(route.path);
  //     route.children.forEach((child) => {
  //       appVue.addRouteToWhiteList(`${route.path}/${child.path}`);
  //     });
  //   });
  //
  //   //#region [ Register Custom HCM Resources ]
  //   appVue.importRes({
  //     Chinese: Chinese,
  //     English: English,
  //     TraditionalChinese: TraditionalChinese,
  //   });
  //   //#endregion
  //
  //   i18n.locale = getLanguage();
  //
  //   importPROSmartIcon(appVue);
  //   installWidgets(appVue.registerCmp);
  //   //#region [ Prototype Sample ]
  //   //   appVue.registerFn("$g", alert); //works
  //   //   Vue.prototype.$test2 = alert; // undefined
  //   //   appVue.__proto__.$test3 = console.log; // works
  //   //#endregion
  // });
  //#endregion
});
