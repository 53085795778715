<template>
  <pro-login-layout @ChangeLanguage="(b) => (language = b)">
    <div class="login-form">
      <el-alert
        v-if="loginRedirect.message"
        :title="
          getRes(loginRedirect.message).replace(
            '{ref}',
            loginRedirect.tender.ref
          )
        "
        type="error"
        :closable="false"
        style="margin-bottom: 16px"
      />

      <div class="form-item">
        <span class="login-font text-primary">{{
          getRes("CustMenu.Login.LoginSign")
        }}</span>

        <q-form
          class="login-form"
          @submit="login"
          v-if="showUsernameLogin"
          method="post"
        >
          <span class="label-font">
            {{ getRes("CustMenu.Login.SupplierCode") }}
          </span>
          <q-input
            outlined
            v-model="info.userName"
            :placeholder="getRes('CustMenu.Login.SupplierCode')"
            style="margin-left: 0"
          />

          <span class="label-font" style="margin-top: 16px">
            {{ getRes("CustMenu.Login.Password") }}
          </span>

          <q-input
            outlined
            type="password"
            v-model="info.password"
            :placeholder="getRes('CustMenu.Login.Password')"
            style="margin-left: 0"
            autocomplete="off"
          />

          <!--          <div class="loginForgotPassword">-->
          <!--            <router-link :to="{ path: '/ForgotPassword' }" class="a-link">-->
          <!--              {{ getRes("CustMenu.Login.ForgotPassword") }}-->
          <!--            </router-link>-->
          <!--          </div>-->

          <q-btn
            style="margin-top: 12px"
            no-caps
            color="primary"
            :label="getRes('CustMenu.Login.Login')"
            class="full-width"
            unelevated
            type="submit"
            :disable="isDisable"
          />
        </q-form>

        <sso-login-button
          :or-label="showUsernameLogin"
          :sso-name="['cicOkta']"
        ></sso-login-button>

        <!-- <q-btn
          style="margin-top: 12px"
          no-caps
          outline
          color="primary"
          type="button"
          :label="getRes('CustMenu.Login.TendererLogin')"
          class="full-width"
          @click="$router.push({ name: 'TendererLogin' })"
          unelevated
        /> -->
      </div>
    </div>

    <template #footer>
      <!-- <terms-of-use /> -->
      <DisclaimerAndCopyright
        :guide="'CustMenu.Field.SupUserGuide'"
        :guide-link="'/file/Supplier_Manual.pdf'"
      />
    </template>
  </pro-login-layout>
</template>

<script>
// import TermsOfUse from "@/components/PROSmart/LoginPage/TermsOfUse";
import DisclaimerAndCopyright from "@/components/PROSmart/LoginPage/DisclaimerAndCopyright";
import ProSmartLoginMixin from "@/views/mixins/ProSmartLogin";
import ProLoginLayout from "@/components/PROSmart/Layout/ProLoginLayout.vue";
import SsoLoginButton from "../LoginPage/SsoLoginButton.vue";
import afterLogin from "../../../events/afterLogin";

export default {
  name: "SupplierLogin",
  components: {
    SsoLoginButton,
    ProLoginLayout,
    DisclaimerAndCopyright,
  },
  mixins: [ProSmartLoginMixin],
  data() {
    return {
      info: {
        userName: "",
        password: "",
      },
      language: "English",
      isDisable: false,
      showUsernameLogin: false,
      loginRedirect: {
        message: "",
        tender: "",
        action: "",
      },
    };
  },
  mounted() {
    localStorage.removeItem("userType");
    afterLogin.resetAfterSSOLogin();

    this.checkLanguage();

    if (this.$route.params) {
      if (this.$route.params.action) {
        this.loginRedirect.tender = this.$route.params.tender;
        this.loginRedirect.action = this.$route.params.action;

        switch (this.loginRedirect.action) {
          case "OpenRegExistingSupplier":
            this.loginRedirect.message =
              "CustMenu.Login.OpenRegExistingSupplierMessage";
            break;
        }
        afterLogin.saveAfterSSOLoginSetting(
          this.$route.params.action,
          this.$route.params.tender.id,
          this.$route.params.tender.ref
        );
      } else {
        this.loginRedirect.action = "";
        this.loginRedirect.message = "";
      }
    }

    this.$proSmart.setting.getCommonLoginSetting(this).then((rs) => {
      this.showUsernameLogin = rs.loginSupplierWithUsername;
    });
  },
  methods: {
    login() {
      this.isDisable = true;

      if (this.info.userName === "" && this.info.password === "") {
        this.alert(
          this.getRes("login.userisemptynotify"),
          this.getRes("notification")
        );

        this.isDisable = false;
        return;
      }

      this.$proSmart.login
        .loginByUserCodePwd(
          this,
          this.info.userName,
          window.sha512(window.sha512(this.info.password)).toLowerCase()
        )
        .then((b) => {
          this.isDisable = false;

          if (!b.tokenId) {
            this.alert(b.info, this.$t("notification"));
            return;
          }

          const success = this.loginHcm(b, this.language);

          // this.JumpUrlInfo.set("menuid", 915);
          // this.JumpUrlInfo.set("params", { username: "eduardo" });
          // this.JumpUrlInfo.set("query", { plan: "private" });

          if (success) {
            afterLogin.processAction.call(this, this.$route.params.action, {
              id: this.loginRedirect.tender.id,
              ref: this.loginRedirect.tender.ref,
            });
          }
        });
    },
    checkLanguage() {
      const Language = localStorage.getItem("Language");

      if (Language === "Chinese") {
        this.language = "Chinese";
      } else if (Language === "English") {
        this.language = "English";
      } else {
        this.language = "TraditionalChinese";
      }
    },
  },
};
</script>

<style scoped>
.login-wrapper .background-img {
  /* width: 51.2%; */
  z-index: -10;
  zoom: 1;
  min-height: 500px;
  background-size: 100% auto;
  background-repeat: no-repeat;
  position: relative;
  background-color: #0066b3;
  overflow: hidden;
  background-position: center;
}

.login-wrapper {
  display: -ms-flexbox;
  display: flex;
  height: 100vh;
}

.login-wrapper .content {
  /* width: 48.8%; */
  box-sizing: border-box;
  padding: 2.15% 1.6% 3% 4%;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}

.login-wrapper .content .main {
  margin-top: 10%;
  margin-left: 1%;
  min-width: 350px;
}

.login-wrapper .content .main .login-font {
  /*width: 97px;*/
  height: 44px;
  /*font-family: BeVietnam;*/
  font-size: 25px;
  font-weight: 600;
  margin: auto 0;
}

.login-wrapper .content .main .login-form .form-item .label-font {
  display: inline-block;
  margin-bottom: 6px;
  line-height: 23px;
  font-size: 16px;
  font-weight: 500;
  color: #212121;
}

.login-wrapper .content .main .login-form {
  margin-top: 25px;
  width: 100%;
}

.login-wrapper .content .main .loginForgotPassword {
  text-align: end;
  margin-top: 16px;
}
</style>
