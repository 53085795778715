const viewFiles = require.context(
  "@/views/modules/myDocument",
  true,
  /\.page\.js$/
);
const viewRoutes = viewFiles
  .keys()
  .map((k) => viewFiles(k).default)
  .sort((a, b) => a.sequence - b.sequence)
  .filter((rs) => {
    if (!rs.portalName) return false;
    let portalList = rs.portalName.split(",");

    if (portalList.length < 1) return false;
    return portalList.includes(process.env.VUE_APP_BUILD_PORTAL);
  })
  .map((module) => ({
    path: module.path || module.name,
    alias: module.alias || [],
    name: module.name,
    component: module.component,
    props: module.props,
    meta: {
      title: module.title,
      sequence: module.sequence,
      isPageInternal: true,
    },
  }));

export default {
  path: "MyDocument", // Url Path
  name: "MyDocument", // Route name
  parent: "Home1", // Parent route path
  component: () => import("./MyDocument.vue"),
  title: "menu.MyDocument", // Form title, locale resource key, it SHOULD be same as menu rkey
  children: viewRoutes,
  sequence: 99, // No use
  portalName: "procurer",
};
