<template>
  <div class="container">
    <div class="content">
      <!--      <el-transfer-->
      <!--        filterable-->
      <!--        :filter-method="handleFiterFlowList"-->
      <!--        v-model="value"-->
      <!--        :data="flowList"-->
      <!--        @change="handleFlowListChange"-->
      <!--        target-order="push"-->
      <!--        :titles="flowListTitle"-->
      <!--      >-->
      <!--      </el-transfer>-->
    </div>
    <div class="footer">
      <el-button ref="btnSave" type="primary" @click="handleSaveFlowList">{{
        this.getRes("save")
      }}</el-button>
      <el-button ref="btnCancel" type="danger" @click="handleCancel">{{
        this.getRes("cancel")
      }}</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "quick-access-setting",
  props: {
    selectedFlowList: Array,
    flowList: Array,
    flowListTitle: Array,
  },
  data() {
    return {
      value: [],
    };
  },
  watch: {
    selectedFlowList(val) {
      console.log("change");
      this.value = val;
    },
  },
  methods: {
    handleFiterFlowList(query, item) {
      let filterStr = query.replace(/^\s+|\s+$/g, "");
      return item.initial.toLowerCase().indexOf(filterStr.toLowerCase()) > -1;
    },
    handleFlowListChange(value, direction, movedKeys) {
      if (value.length > 3) {
        var lnIndex = value.indexOf(movedKeys[0]);

        if (lnIndex >= 0) {
          var loValue = value.splice(0, 4);
          this.value = loValue;
        }
      }
    },
    handleSaveFlowList() {
      this.$emit("onSave", this.value);
    },
    handleCancel() {
      this.$emit("onCancel");
    },
  },
};
</script>
