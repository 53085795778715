<template>
  <div class="row q-col-gutter-xs">
    <div class="col-auto">
      <a href="#">{{ getRes("CustMenu.Field.TermsOfUse") }}</a>
    </div>
    <div class="col-auto">|</div>
    <div class="col-auto">
      <a href="#">{{ getRes("CustMenu.Field.PrivacyPolicy") }}</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "TermsOfUse",
};
</script>

<style lang="scss" scoped>
@import "@/styles/quasar.variables";

a {
  color: $primary;
  text-decoration: underline;
}
</style>
