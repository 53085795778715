import './styles/quasar.scss';
import 'quasar/dist/quasar.ie.polyfills';
import '@quasar/extras/material-icons/material-icons.css';
import Quasar from 'quasar/src/vue-plugin.js';import Notify from 'quasar/src/plugins/Notify.js';import Dialog from 'quasar/src/plugins/Dialog.js';;
window.Quasar = Quasar;
export function installQuasar(Vue) {
  Vue.use(Quasar, {
    plugins: {
      Notify,
      Dialog,
    },
    config: {
      notify: {},
      Dialog: {}
    },
  });
}
