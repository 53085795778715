export default {
  path: "SupplierList", // Url Path
  name: "SupplierList", // Route name
  parent: "Home1", // Parent route path
  component: () =>
    // @ts-ignore
    import("./SupplierList.vue"),
  title: "menu.SupplierList", // Form title, locale resource key, it SHOULD be same as menu rkey
  sequence: 99, // No use
  portalName: "procurer",
};
