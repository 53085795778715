export default {
  path: "ViewTendererForm/:mode/:code/:stepperId/:tendererId/:companyName",
  name: "DocumentViewTendererForm", // Route name
  component: () => import("./viewForm.vue"),
  props: (route) => ({
    injectFormData: {
      tendererId: route.params.tendererId,
      companyName: route.params.companyName,
    },
  }),
  title: "menu.ViewTendererForm", // Form title, locale resource key, it SHOULD be same as menu rkey
  portalName: "procurer",
};
