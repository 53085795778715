<template>
  <pro-login-layout @ChangeLanguage="(b) => (language = b)">
    <div class="disclaimer-container q-pa-md">
      <div class="disclaimer-page" v-html="disclaimerString" />
    </div>

    <template #footer>
      <div class="row footer-button">
        <div class="col">
          <q-btn
            no-caps
            color="primary"
            :label="getRes('Form.Action.Previous')"
            unelevated
            @click="goBack(fromPath)"
          />
          <div class="copyright" style="pointer-events: none; margin-top: 30px">
            {{ getRes("Form.Field.Copyright") }} © {{ getNowYear() }} ({{
              companyName
            }}). {{ getRes("Form.Field.AllRightsReserved") }}.
          </div>
        </div>
      </div>
    </template>
  </pro-login-layout>
</template>

<script>
import ProSmartLoginMixin from "@/views/mixins/ProSmartLogin";
import ProLoginLayout from "@/components/PROSmart/Layout/ProLoginLayout.vue";

export default {
  name: "DisclaimerPage",
  components: { ProLoginLayout },
  mixins: [ProSmartLoginMixin],
  data() {
    return {
      language: "English",
      fromPath: "",
      companyName: "",
      disclaimer: { en: "", tn: "", cn: "" },
      disclaimerString: "",
    };
  },
  created() {
    this.InitDisclaimer();
  },
  methods: {
    getNowYear() {
      return new Date().getFullYear();
    },
    InitDisclaimer() {
      this.$proSmart.setting.getDisclaimerSetting(this).then((res) =>
        this.$proSmart.setting.getWebsiteInfoSetting(this).then((key) => {
          this.companyName = key.companyName;

          this.InitDisclaimerStr(res, key);
          this.InitDisclaimerByLanguage(this.language);
        })
      );
    },
    InitDisclaimerByLanguage(language) {
      switch (language) {
        case "Chinese":
          this.disclaimerString = this.disclaimer.cn.body;
          break;
        case "TraditionalChinese":
          this.disclaimerString = this.disclaimer.tn.body;
          break;
        case "English":
          this.disclaimerString = this.disclaimer.en.body;
          break;
        default:
          this.disclaimerString = this.disclaimer.en.body;
          break;
      }
    },
    InitDisclaimerStr(setting, keyPlaceholder) {
      let map = {
        cn: "disclaimerCICCn",
        en: "disclaimerCICEn",
        tn: "disclaimerCICTn",
      };

      for (let mapKey in map) {
        this.disclaimer[mapKey] = this.replacePlaceholders(
          JSON.parse(setting[map[mapKey]]),
          keyPlaceholder
        );
      }
    },
    replacePlaceholders(str, keyPlaceholder) {
      for (let keyPlaceholderKey in keyPlaceholder) {
        str.body = str.body.replaceAll(
          `{{${keyPlaceholderKey}}}`,
          keyPlaceholder[keyPlaceholderKey]
        );
      }

      return str;
    },
    goBack(path) {
      if (path && path !== "/Disclaimer") {
        this.$router.push(path);
      } else {
        this.$router.back();
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => (vm.fromPath = from.fullPath));
  },
  watch: {
    language(newValue) {
      this.InitDisclaimerByLanguage(newValue);
    },
  },
};
</script>

<style lang="scss" scoped>
.login-wrapper .background-img {
  width: 51.2%;
  z-index: -10;
  zoom: 1;
  min-height: 500px;
  background-size: 100% auto;
  background-repeat: no-repeat;
  position: relative;
  background-color: #0066b3;
  overflow: hidden;
}

.login-wrapper {
  display: -ms-flexbox;
  display: flex;
  height: 100vh;
}

.login-wrapper .content .main .disclaimer-container {
  margin-top: -5% !important;
  min-width: 350px;

  .disclaimer-page {
    font-size: 18px;
    line-height: 1.8em;
    text-align: justify;
  }
}

.login-wrapper .content .footer .footer-button {
  margin-left: 3% !important;
}
</style>
