import { getLanguage } from "@/util";

export default {
  methods: {
    /**
     * @param returnData {{result: import("@pro-smart/hcm-custom-project-api-middleware/proSmart").proSmartLoginResult}}}
     * @param language
     * @returns {boolean}
     */
    loginHcm(returnData, language) {
      const res = returnData.result;

      const lang = language || "English";
      const nameKey = `${lang[0].toLowerCase()}_name`;

      if (returnData.$) {
        //var tokenId = returnData.tokenId;
        this.LocalUser.set("UserName", res[nameKey]);
        this.LocalUser.set("UserCode", res.userCode);
        this.LocalUser.set("LoginUserCode", res.userCode);
        this.LocalUser.set("Language", language);

        window.Lark &&
          window.Lark.ServiceAjax.setInvokeAttr({
            UserCode: res.userCode,
            LoginUserCode: res.userCode,
            Language: language,
          });
        this.$root.$i18n.locale = getLanguage();

        this.LocalUser.set("UserId", res.userId);
        this.LocalUser.set("UserIdOrigin", res.userId);
        this.LocalUser.set("LoginUserId", res.userId);
        this.LocalUser.set("TokenId", returnData.tokenId);
        this.LocalUser.set("SerialNumber", res.serialNumber);
        this.LocalUser.set("EmpCode", res.empCode);
        // this.LocalUser.set('PasswordHash', pwdHash);
        // this.LocalUser.set('Authencated', rempassword);
        this.LocalUser.set("DateFormat", res.dateFormat);
        //localStorage.setItem("DateFormat",res.dateFormat);
        this.LocalUser.set("CommonMsg", res.commonmsg);
        this.LocalUser.set("EName", res.e_name);
        this.LocalUser.set("LoginEName", res.e_name);
        this.LocalUser.set("CName", res.c_name);
        this.LocalUser.set("LoginCName", res.c_name);
        this.LocalUser.set("TName", res.t_name);
        this.LocalUser.set("LoginTName", res.t_name);
        this.LocalUser.set("UserLevel", res.userlevel);
        this.LocalUser.set("pageSize", res.pageSize);
        localStorage.setItem("PageSize", res.pageSize);
        this.LocalUser.set("HomePage", this.splitHomePage(res.homepage));
        this.LocalUser.set("QuickProcesses", res.quickprocesses);
        this.LocalUser.set("IPLCompNo", res.iplcompno);
        this.LocalUser.set("useSHA512", true);

        this.LocalUser.saveToStorage();

        console.log("ProSmartLogin this", this);
        console.log("localstorage userType", localStorage.getItem("userType"));
        this.CustomPathMap.login = this.$route.path;

        window.Lark.ServiceAjax.setTokenID(returnData.tokenId);
        window.Lark.BinaryAjax.setTokenID(returnData.tokenId);

        this.invokeService("PCSystem", "SetLanguage", [], (res) => {
          console.log(res);
        });

        if (res.LicenseValidated === false) {
          this.flyMsg(this.$t("login.invalidLicense"));
        }

        if (res.lastlogontime_pc && res.lastlogontime_pc instanceof Date) {
          localStorage.setItem("LastLoginTime", res.lastlogontime_pc.valueOf());
        } else {
          localStorage.removeItem("LastLoginTime");
        }

        localStorage.setItem(
          "DefaultCoverPath",
          window.Lark.ServiceAjax.getHost() +
            "/Images/AnnouncementIcons/DefaultCover.png?t=" +
            Date.now()
        );

        return true;
      } else {
        return false;
      }
    },
    splitHomePage(pairUrl) {
      if (pairUrl == null) {
        return "";
      } else if (pairUrl.indexOf(";") <= 0) {
        return "";
      } else {
        return pairUrl.substring(pairUrl.indexOf(";") + 1);
      }
    },
  },
};
