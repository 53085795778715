<template>
  <div>
    <div v-if="Object.keys(actionButtonList).length === 0"></div>
    <q-card class="box-card" v-else>
      <q-card-section>
        <div v-for="key in orderList" :key="key">
          <div v-if="actionButtonList[key]">
            <div
              class="text-h4 text-brand row"
              style="padding: 0 0 10px 10px"
              v-text="groupNameMapping(key)"
            />
            <div class="row" style="margin-bottom: 10px">
              <div
                v-for="item in actionButtonList[key]"
                :key="item.name"
                class="col-6"
                style="padding: 3px"
              >
                <q-card
                  style="height: 40px; padding: 5px"
                  @click="jump(item.path)"
                  class="shortcut-card"
                >
                  <div class="row" style="height: 30px">
                    <q-icon
                      :name="item.icon"
                      size="32px"
                      class="col-2 shortcut-card-icon"
                      style="height: 30px"
                    />
                    <div class="col-10" style="height: 30px; display: flex">
                      <span
                        class="shortcut-card-title"
                        style="margin: auto 0"
                        >{{ $t(item.name) }}</span
                      >
                    </div>
                  </div>
                </q-card>
              </div>
            </div>
          </div>
        </div>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
export default {
  name: "hcm-workbench-widget",
  data() {
    return {
      actionButtonList: {},
      orderList: [
        "common",
        "procurer",
        "buyer",
        "tenderer",
        "registeringSupplier",
        "supplier",
        "SystemAdmin",
      ],
    };
  },
  async beforeCreate() {
    if (this.LocalUser.UserId !== this.LocalUser.LoginUserId) {
      return;
    }

    this.$proSmart.dashboard.getMenuShortcutByUserGroup(this).then((b) => {
      let menuList = {};

      b.forEach((item) => {
        if (!menuList[item.groupName]) {
          menuList[item.groupName] = [];
        }

        menuList[item.groupName].push(item);
      });

      this.actionButtonList = menuList;
    });
  },
  methods: {
    jump(path) {
      this.$router.push(path);
    },
    groupNameMapping(name) {
      switch (name) {
        case "buyer":
          return this.$t("System.Doc.UserRole.Buyer");
        case "procurer":
          return this.$t("CustMenu.Field.Procurer");
        case "common":
          return "";
        case "tenderer":
          return this.$t("CustMenu.Field.Tenderer");
        case "supplier":
        case "registeringSupplier":
          return this.$t("CustMenu.Field.Supplier");
        case "SystemAdmin":
          return this.$t("CustMenu.Field.SystemAdmin");
        default:
          return name;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/quasar.variables";

.text-h4.text-brand {
  color: $primary !important;
}

.shortcut-card {
  border-radius: 4px;
  box-shadow: 0 0 5px 0 rgba(101, 101, 101, 0.5);
  background-color: #f9f9f9;
  cursor: pointer;

  &-icon {
    color: $primary !important;
  }

  &-title {
    color: $primary !important;
  }
}

/* Flex layout */
//.links-container {
//  display: flex;
//  flex-wrap: wrap;
//
//  .link-wrapper {
//    display: flex;
//    justify-content: center;
//    align-items: center;
//    margin: 4px 0 4px 8px;
//
//    .link {
//      text-align: center;
//      display: flex;
//      flex-direction: column;
//      justify-content: center;
//      align-items: center;
//      width: 9.5rem;
//      height: 9.5rem;
//      border-radius: 4px;
//      box-shadow: 0 0 5px 0 rgba(101, 101, 101, 0.5);
//      background-color: #f9f9f9;
//      cursor: pointer;
//      padding: 0.5rem;
//
//      > img {
//        width: 3.5rem;
//        height: 3.5rem;
//        object-fit: cover;
//        // min-width: 28px;
//        // min-height: 28px;
//        margin: 10px;
//      }
//
//      &-text {
//        height: 40px;
//        display: flex;
//        align-items: center;
//        justify-content: center;
//      }
//    }
//  }
//}

/* Grid layout */
// .links-container {
//   display: grid;
//   grid-template-columns: repeat(auto-fill, 9.5rem);
//   grid-gap: 10px;
//   justify-content: center;

//   .link-wrapper {
//     display: flex;
//     justify-content: center;
//     align-items: center;

//     .link {
//       text-align: center;
//       display: flex;
//       flex-direction: column;
//       justify-content: center;
//       align-items: center;
//       width: 9.5rem;
//       height: 9.5rem;
//       border-radius: 4px;
//       box-shadow: 0 0 5px 0 rgba(101, 101, 101, 0.5);
//       background-color: #f9f9f9;
//       cursor: pointer;

//       > img {
//         width: 35px;
//         height: 35px;
//         object-fit: cover;
//         // min-width: 28px;
//         // min-height: 28px;
//         margin: 10px;
//       }

//       &-text {
//         height: 40px;
//         display: flex;
//         align-items: center;
//         justify-content: center;
//       }
//     }
//   }
// }
</style>
