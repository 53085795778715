<template>
  <div>
    <div class="card-body normal widget">
      <div class="widget-header">
        <div class="widget-title">
          {{ this.getRes("Dashboard.UpcomingMeetingList") }}
        </div>
        <div class="widget-action">
          <q-btn
            flat
            no-caps
            padding="xs"
            color="primary"
            icon="PROSmart-Calendar"
            :to="{ name: 'Calendar' }"
          />
        </div>
      </div>
      <div class="widget-body">
        <q-scroll-area style="width: 100%" v-show="eventList.length > 0">
          <q-list separator>
            <q-item v-for="event in eventList" :key="event.id" :to="{}">
              <q-item-section>
                <q-item-label class="text-h5">{{
                  event.description
                }}</q-item-label>
                <q-item-label class="text-h6 text-grey-6">{{
                  event.ref
                }}</q-item-label>
              </q-item-section>
              <q-item-section side top>
                <q-item-label class="text-h6" v-if="event.startDate">{{
                  String.format(
                    getRes("Calendar.StartTime"),
                    $proSmart.common.format.dateTime(event.startDate)
                  )
                }}</q-item-label>
                <q-item-label class="text-h6" v-if="event.endDate">{{
                  String.format(
                    getRes("Calendar.EndTime"),
                    $proSmart.common.format.dateTime(event.endDate)
                  )
                }}</q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-scroll-area>
        <div v-show="eventList.length === 0">
          {{ getRes("Calendar.NoMeetings") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProUpcomingMeetingList",
  data() {
    return {
      eventList: [
        {
          id: 1,
          description: "Tender Interview",
          ref: "Tender CONTRACT NO.: NL/2020/07-35",
          subjEng: "Tung Chung New Town Extension - Tai Ho Interchange",
          endDate: new Date("2021-10-11 11:00"),
        },
        {
          id: 2,
          description: "Post Tender Negotiation",
          ref: "Tender CONTRACT NO.: NL/2020/07-35",
          subjEng: "Tung Chung New Town Extension - Tai Ho Interchange",
          startDate: new Date("2021-10-11 12:00"),
          endDate: new Date("2021-10-11 14:00"),
        },
        {
          id: 3,
          description: "Site Visit",
          ref: "Tender Project DL-11",
          subjEng:
            "Foundation Works for Project DL-11-1:YTM - Proposed Composite Development at No.5-13 Ash Street, Tai Kok Tsui, Kowloon",
          endDate: new Date("2021-10-13"),
        },
        {
          id: 4,
          description: "Deliberation Meeting",
          ref: "Tender Project DL-11",
          subjEng:
            "Foundation Works for Project DL-11-1:YTM - Proposed Composite Development at No.5-13 Ash Street, Tai Kok Tsui, Kowloon",
          endDate: new Date("2021-10-15"),
        },
        {
          id: 5,
          description: "Sampling",
          ref: "Tender CONTRACT NO.: NL/2020/07-35",
          subjEng: "Tung Chung New Town Extension - Tai Ho Interchange",
          endDate: new Date("2021-10-18"),
        },
      ],
    };
  },
};
</script>
