import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

import whmEnUs from "./locales/WFM/en-US.json";
import whmZhHant from "./locales/WFM/zh-Hant.json";
import whmZhHans from "./locales/WFM/zh-Hans.json";

import enUs from "./locales/en-US.json";
import zhHant from "./locales/zh-Hant.json";
import zhHans from "./locales/zh-Hans.json";

function loadLocaleMessages() {
  return {
    ["en-US"]: { ...whmEnUs, ...enUs },
    ["zh-Hant"]: { ...whmZhHant, ...zhHant },
    ["zh-Hans"]: { ...whmZhHans, ...zhHans },
  };
}

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || "en",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  messages: loadLocaleMessages(),
});
