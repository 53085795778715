<template>
  <pro-login-layout>
    <div class="login-form" v-if="ref === ''">
      <q-skeleton height="80px" style="margin-top: 10px" />
      <q-skeleton height="50px" style="margin-top: 10px" />
    </div>
    <div
      class="login-form"
      v-if="ref !== '' && this.$route.params.action === 'SubmitInterest'"
    >
      <span class="login-font">{{ $t("Form.Section.SubmitInterest") }}</span>
      <div style="padding-left: 16px">
        {{
          $t("Form.Field.SubmitInterestDescribe", {
            moduleName: moduleName,
            date: $proSmart.common.getSubmitInterestDate(),
          })
        }}
      </div>
    </div>
    <div
      class="login-form"
      v-if="ref !== '' && this.$route.params.action === 'ReplySlip'"
    >
      <span class="login-font">{{ $t("Form.Section.Acknowledgement") }}</span>
      <div style="padding-left: 16px">
        {{ $t("Form.Section.ReplySlipDescribe", { moduleName: moduleName }) }}
      </div>
    </div>
    <div class="login-form" v-if="ref !== '' && moduleName">
      <span class="login-font">{{ $t(`${moduleName} Information`) }}</span>
      <q-markup-table dense flat wrap-cells separator="none">
        <thead></thead>
        <tbody>
          <tr class="q-tr--no-hover">
            <td>{{ $t("Form.Field.TenderRef") }}:</td>
            <td style="padding-left: 10px">{{ ref }}</td>
          </tr>
          <tr class="q-tr--no-hover">
            <td>{{ $t("Form.Field.Subject") }}:</td>
            <td
              style="
                padding-left: 10px;
                word-wrap: break-word;
                word-break: break-all;
                width: 380px;
              "
            >
              {{ subjEng }}
            </td>
          </tr>
        </tbody>
      </q-markup-table>
    </div>
    <div class="login-form" v-if="ref !== ''">
      <q-btn
        no-caps
        @click="
          $router.push({ name: 'TendererLogin', query: { code: securityCode } })
        "
        outline
        color="primary"
        :label="$t('Form.Field.TendererLogin')"
        class="full-width"
        unelevated
        type="submit"
      />
    </div>
  </pro-login-layout>
</template>

<script>
import ProLoginLayout from "@/components/PROSmart/Layout/ProLoginLayout.vue";
export default {
  name: "EmailAction",
  components: { ProLoginLayout },
  data() {
    return {
      ref: "",
      subjEng: "",
      subjChi: "",
      moduleName: "",
      securityCode: "",
    };
  },
  async created() {
    let { emailUid, securityCode, action } = this.$route.params;
    this.securityCode = securityCode;

    let rs = await this.$proSmart.tendererUi.submitReplySlipOrSubmitInterest(
      this,
      emailUid,
      securityCode,
      action
    );

    if (!rs.moduleName) {
      this.$router.push({ name: "TendererLogin" });
      return;
    }

    this.ref = rs.ref;
    this.subjChi = rs.subjChi;
    this.subjEng = rs.subjEng;
    this.moduleName = rs.moduleName;
  },
};
</script>

<style scoped></style>
