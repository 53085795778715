<template>
  <div class="disclaimer_and_copyright row">
    <div class="col col-md-8">
      <div class="disclaimer">
        <router-link :to="{ path: '/Disclaimer' }" class="a-link">
          {{ getRes("CustMenu.Field.Disclaimer") }}
        </router-link>
        <div class="copyright" style="pointer-events: none; cursor: default">
          {{ getRes("Form.Field.Copyright") }} ©
          {{ getNowYear() }}
          ({{ companyName }}). {{ getRes("Form.Field.AllRightsReserved") }}.
        </div>
      </div>
    </div>
    <div class="col col-md-4" v-if="guideLink">
      <a :href="guideLink" class="a-link" target="_blank">
        {{ getRes(guide) }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "DisclaimerAndCopyright",
  props: {
    guide: {
      type: String,
      default: () => "CustMenu.Field.UserGuide",
    },
    guideLink: {
      type: String,
      default: () => "",
    },
  },
  data() {
    return {
      companyName: "",
    };
  },

  mounted() {
    this.getCompanyName();
  },

  methods: {
    getNowYear() {
      let date = new Date();
      return date.getFullYear();
    },
    getCompanyName() {
      this.$proSmart.setting
        .getWebsiteInfoSetting(this)
        .then(({ companyName }) => (this.companyName = companyName));
    },
  },
};
</script>
