<template>
  <div>
    <div v-if="!actionButtonList || actionButtonList.length < 1"></div>
    <template v-else>
      <q-card class="box-card">
        <q-card-section>
          <div class="row">
            <div class="row col-11">
              <template v-for="item in actionButtonList">
                <div
                  :key="item.name"
                  v-if="item.name"
                  class="col-2"
                  style="padding: 3px"
                  @click="goApply(item.processcode, item.name)"
                >
                  <q-card class="shortcut-card" style="height: 100%">
                    <div class="row">
                      <div
                        class="col-12"
                        style="text-align: center; height: 100%"
                      >
                        <img
                          class="shortcut-card-img"
                          :src="item.imgUrl"
                          alt=""
                          style="width: 35px; height: 35px; margin-top: 10px"
                        />
                      </div>
                      <div
                        class="col-12"
                        style="text-align: center; padding: 5px"
                      >
                        <span class="shortcut-card-title">{{ item.name }}</span>
                      </div>
                    </div>
                  </q-card>

                  <!--            <div class="">-->
                  <!--              <img :src="item.imgUrl" alt="" />-->
                  <!--              <div class="">-->
                  <!--                <span>{{ item.name }}</span>-->
                  <!--              </div>-->
                  <!--            </div>-->
                </div>
              </template>
            </div>
            <!--        <q-icon name="settings" size="25px" style="width: 4px" />-->
          </div>
        </q-card-section>
      </q-card>
    </template>
    <!--    <div-->
    <!--      v-if="this.LocalUser.UserId == this.LocalUser.LoginUserId"-->
    <!--      class="widget-action"-->
    <!--      @click="showSetting"-->
    <!--    >-->
    <!--      &lt;!&ndash;      <img&ndash;&gt;-->
    <!--      &lt;!&ndash;        src="../../../../static/image/quickaction-setting.png"&ndash;&gt;-->
    <!--      &lt;!&ndash;        srcset="&ndash;&gt;-->
    <!--      &lt;!&ndash;          ./static/image/quickaction-setting@2x.png 2x,&ndash;&gt;-->
    <!--      &lt;!&ndash;          ./static/image/quickaction-setting@3x.png 3x&ndash;&gt;-->
    <!--      &lt;!&ndash;        "&ndash;&gt;-->
    <!--      &lt;!&ndash;        height="19.6px"&ndash;&gt;-->
    <!--      &lt;!&ndash;        width="19.6px"&ndash;&gt;-->
    <!--      &lt;!&ndash;      />&ndash;&gt;-->
    <!--    </div>-->
    <!--    <el-dialog-->
    <!--      QA="/modules/control/QuickAccessWidget/QuickAccessWidget_1622781511981_0"-->
    <!--      :title="cEditQuickAccessTitle"-->
    <!--      :visible.sync="bShowEditQuickAccess"-->
    <!--      :before-close="onCancel"-->
    <!--      class="quick-access-setting-dialog"-->
    <!--    >-->
    <!--      <quick-access-setting-->
    <!--        :selectedFlowList="selectedFlowList"-->
    <!--        :flowList="flowList"-->
    <!--        :flowListTitle="flowListTitle"-->
    <!--        @onCancel="onCancel"-->
    <!--        @onSave="onSave"-->
    <!--      >-->
    <!--      </quick-access-setting>-->
    <!--    </el-dialog>-->
  </div>
</template>

<script>
// import QuickAccessSetting from "../quickAccessSetting";

export default {
  components: {},
  name: "hcm-quick-access",
  data() {
    return {
      dialogVisible: false,
      linkRowCount: 4,
      bShowEditQuickAccess: false,
      /** @type {Array<{uid: string, processcode: string, name: string, modelcategory: string, version: number, releaseversion:number, processtype: string, url: string, imgUrl: string}>}} **/
      actionButtonList: [],
      candidateActionList: [],
      flowList: [],
      selectedFlowList: [],
      cEditQuickAccessTitle: this.getRes("Dashboard.EditQuickAccess"),
      flowListTitle: [
        this.getRes("Dashboard.EditQuickAccess.Unselected"),
        this.getRes("Dashboard.EditQuickAccess.Selected"),
      ],
    };
  },
  mounted() {
    this.hostURL = window.Lark.ServiceAjax.getHost();
    this.prepareActionButtonList();
  },
  inject: ["HandleFlowButtonClick"],
  methods: {
    showSetting() {
      this.bShowEditQuickAccess = true;
      this.prepareCandidateActionList();
    },
    prepareCandidateActionList() {
      this.invokeService(
        "Workflow",
        "GetProcessModelInPermission",
        [this.LocalUser.get("UserId")],
        (msg) => {
          if (msg.ReturnData.$) {
            let models = msg.ReturnData.$.toJson();
            this.candidateActionList = models.map((m) => {
              return Object.assign({}, m, {
                url: "",
                imgUrl: `${this.hostURL}/Images/ProcessIcons/${m.processcode}.svg`,
              });
            });
            this.flowList = models.map((m) => {
              return {
                label: m.name,
                key: m.processcode,
                initial: m.name,
              };
            });
          }

          this.hideLoadMask();
        },
        () => {
          console.log("GetProcessModelInPermission error!");
          this.hideLoadMask();
        }
      );
    },
    prepareActionButtonList() {
      if (this.LocalUser.QuickProcesses) {
        let processes = this.LocalUser.get("QuickProcesses");
        this.actionButtonList = processes.toJson().map((p) => {
          return Object.assign({}, p, {
            url: "",
            imgUrl: `${this.hostURL}/Images/ProcessIcons/${p.processcode}.svg`,
          });
        });
      }
    },
    saveActionButtonListToServer(processCodes) {
      this.invokeService(
        "MyAccount",
        "SaveUserQuickProcess",
        [this.LocalUser.get("UserId"), processCodes],
        () => {},
        (err) => {
          alert(
            `${this.getRes("NewHomePage.SaveUserQuickProcessFailed")} : ${err}`
          );
          return;
        }
      );
    },
    HandleCloseEditQuickAccess() {},
    onCancel() {
      this.bShowEditQuickAccess = false;
    },
    // onSave(val) {
    //   this.actionButtonList = this.getActionList(this.candidateActionList, val);
    //   let { columns, data } = this.LocalUser.get("QuickProcesses");
    //   let newData = this.actionButtonList.map((l) => {
    //     return columns.map((c) => l[c.Name]);
    //   });
    //   this.saveActionButtonListToServer(val.join()); //this.selectedFlowList.join()
    //   this.LocalUser.set(
    //     "QuickProcesses",
    //     new Lark.DataTable({ columns, data: newData })
    //   );
    //   this.LocalUser.saveToStorage();
    //   this.bShowEditQuickAccess = false;
    // },

    goApply(processCode, processName) {
      this.invokeService(
        "Workflow",
        "GetLatestProcessModelId",
        [this.LocalUser.get("UserId"), processCode],
        (msg) => {
          if (msg.ReturnData.$) {
            let procesModelId = msg.ReturnData.$;
            this.HandleFlowButtonClick(procesModelId, processName);
          } else {
            alert(this.getRes("WorkflowError.GetProcessModelIdFailed"));
          }
        },
        (err) => {
          alert(
            `${this.getRes("WorkflowError.GetProcessModelIdFailed")} : ${err}`
          );
        }
      );
    },
    getSelectedFlowList(flowList, actionButtonList) {
      if (!flowList || !actionButtonList) {
        return [];
      }

      return flowList
        .filter((f) => {
          return (
            actionButtonList.findIndex((a) => a.processcode === f.key) > -1
          );
        })
        .map((f) => f.key);
    },
    getActionList(candidateActionList, selected) {
      if (!candidateActionList || !selected) {
        return [];
      }

      return candidateActionList.filter((c) => {
        return selected.findIndex((s) => s === c.processcode) > -1;
      });
    },
  },
  watch: {
    // flowList(va) {
    //   console.log("flowList changed");
    //   console.log(this.actionButtonList);
    //   this.selectedFlowList = this.getSelectedFlowList(
    //     val,
    //     this.actionButtonList
    //   );
    // },
    actionButtonList(val) {
      this.selectedFlowList = this.getSelectedFlowList(this.flowList, val);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/quasar.variables";

.shortcut-card {
  border-radius: 4px;
  box-shadow: 0 0 5px 0 rgba(101, 101, 101, 0.5);
  background-color: #f9f9f9;
  cursor: pointer;

  .shortcut-card-img {
    filter: $primary-filter;
  }

  &-title {
    color: $primary !important;
  }
}
</style>
