export default {
  data() {
    return {
      isShowBackButton: false,
      isShowNextButton: false,
    };
  },
  methods: {
    updateStepper() {
      let stepperInfo = this.getStepperInfo();

      if (stepperInfo) {
        this.isShowBackButton =
          stepperInfo.index < stepperInfo.steps.length &&
          stepperInfo.index !== 0;
        this.isShowNextButton = !stepperInfo.isLast;
      }

      return {
        isShowBackButton: this.isShowBackButton,
        isShowNextButton: this.isShowNextButton,
      };
    },
    getStepperInfo() {
      let stepper = this.getStepper();

      if (stepper) {
        return stepper.getStepInfo();
      }
    },
    getStepper() {
      /** @type {formRender} */
      let formRender = this.getFormRender();

      let allWidgets = formRender.context.allWidgets;

      for (let widgetsKey in allWidgets) {
        let widget = allWidgets[widgetsKey];

        if (widget.type === "stepper") {
          return formRender.context.widgetRefDict[widget.key];
        }
      }
    },
    stepperAction(action) {
      let stepper = this.getStepper();

      return stepper
        ._getEventHandler(action)()
        .then(() => {
          return this.updateStepper();
        });
    },
  },
};
