// let apiName = "PROSmart";
//
// function invokeService(_this, apiNameString, methodName, args = []) {
//   return new Promise((resolve, reject) => {
//     _this.invokeService(
//       apiNameString,
//       methodName,
//       args,
//       function (msg) {
//         resolve(JSON.parse(msg.ReturnData.$));
//       },
//       function (msg) {
//         reject(msg);
//       }
//     );
//   });
// }
//
// const common = {
//   format: {
//     dateTime(d) {
//       return d.format("Y/m/d H:i");
//     },
//     date(d) {
//       return d.format("Y/m/d");
//     },
//   },
// };

// const proSmart = {
//   common,
//   getDocumentList(_this) {
//     return new Promise((resolve, reject) => {
//       invokeService(_this, apiName, "GetDocumentList", [])
//         .then((list) => {
//           list.forEach((t) => {
//             t.closingDate = common.format.dateTime(new Date(t.closingDate));
//           });
//           resolve(list);
//         })
//         .catch((e) => reject(e));
//     });
//   },
//   Tender: {
//     getStepper(_this, tenderId) {
//       return invokeService(_this, apiName, "GetStepper", [tenderId]);
//     },
//     getActionMenu(_this, tenderId, stepperId) {
//       return invokeService(_this, apiName, "GetActionMenu", [
//         parseInt(tenderId),
//         parseInt(stepperId),
//       ]);
//     },
//     getTenderWorkflowInfo(_this, tenderId, workflowCode) {
//       return invokeService(_this, apiName, "GetWorkflow", [
//         parseInt(tenderId),
//         workflowCode,
//       ]);
//     },
//     getWorkflowDefaultResult(_this, tenderId, workflowCode) {
//       return invokeService(_this, apiName, "GetWorkflowDefaultResult", [
//         parseInt(tenderId),
//         workflowCode,
//       ]);
//     },
//     getInfo(_this, tenderId) {
//       return invokeService(_this, apiName, "GetInfo", [parseInt(tenderId)]);
//     },
//   },
//   CodeTable: {
//     getTelCountryRegion(_this) {
//       return invokeService(_this, apiName, "GetTelCountryRegion");
//     },
//     getCommodityCode(_this) {
//       return invokeService(_this, apiName, "GetCommodityCode");
//     },
//     getRouteWorkflowCode(_this, routeName, formCode) {
//       return invokeService(_this, "PROSmartCodeTable", "GetRouteWorkflowCode", [
//         routeName,
//         formCode,
//       ]);
//     },
//     getNonWorkingDate(_this) {
//       return invokeService(_this, "PROSmartCodeTable", "GetNonWorkingDate");
//     },
//   },
//   Workflow: {
//     getLatestProcessModelId(_this, processCode) {
//       return invokeService(
//         _this,
//         "PROSmartHcmService",
//         "GetLatestProcessModelId",
//         [processCode]
//       );
//     },
//     getStartForm(_this, processModelId, userId) {
//       return new Promise((resolve, reject) => {
//         _this.invokeService(
//           "FormRuntime",
//           "GetStartForm",
//           [processModelId, userId, ""],
//           (msg) => {
//             if (msg.ReturnData.$) {
//               resolve(msg.ReturnData.formDefinition);
//             } else {
//               reject(msg);
//             }
//           },
//           reject
//         );
//       });
//     },
//     getFormDefinitionByCode(_this, formCode) {
//       return new Promise((resolve, reject) => {
//         _this.invokeService(
//           "FormRuntime",
//           "GetFormDefinitionByCode",
//           [formCode, ""],
//           (msg) => {
//             if (msg.ReturnData.$) {
//               resolve(msg.ReturnData.formDefinition);
//             } else {
//               reject(msg);
//             }
//           },
//           reject
//         );
//       });
//     },
//     createProcess(
//       _this,
//       processModelId,
//       processOwnerId,
//       createUserId,
//       processParams,
//       startImmediately
//     ) {
//       return new Promise((resolve, reject) => {
//         _this.invokeService(
//           "Workflow",
//           "CreateProcess",
//           [
//             processModelId,
//             processOwnerId,
//             createUserId,
//             processParams,
//             {},
//             startImmediately,
//             "",
//             "",
//           ],
//           (msg) => {
//             if (msg.ReturnData) {
//               resolve(msg.ReturnData);
//             } else {
//               reject(msg);
//             }
//           },
//           reject
//         );
//       });
//     },
//   },
// };

import proSmart from "@pro-smart/hcm-custom-project-api-middleware";

export function installPROSmart(Vue) {
  Vue.use(proSmart);
}
