import Cookies from "js-cookie";

function resetAfterSSOLogin() {
  Cookies.remove("afterSSOLogin");
}

/**
 * @param {string} action
 * @param {string} id
 * @param {string} ref
 */
function saveAfterSSOLoginSetting(action, id, ref) {
  Cookies.set(
    "afterSSOLogin",
    JSON.stringify({
      action: action,
      tenderId: id,
      tenderRef: ref,
    }),
    { expires: 1 }
  );
}

/**
 * @returns {{action: string, tenderId: number, tenderRef: string}}
 */
function getAfterSSOLoginSetting() {
  let afterSSOLogin = Cookies.get("afterSSOLogin");
  if (!afterSSOLogin) return afterSSOLogin;
  return JSON.parse(afterSSOLogin);
}

/**
 * @param {string | null} [action=null] action
 * @param {{id?:number, ref?:string}} [obj={}] obj
 */
function processAction(action = null, obj = {}) {
  switch (action) {
    case "OpenRegExistingSupplier":
      this.$proSmart.supplier
        .registerForOpenDocument(this, obj.id)
        .then(() => {
          this.alert(
            this.$t("Form.Message.RegisterDocumentSuccess", {
              ref: obj.ref,
            }),
            this.getRes("notification")
          );
          this.$router.replace("/Dashboard");
        })
        .catch((ex) => {
          console.error(ex);
        });
      resetAfterSSOLogin();
      this.$router.replace("/Dashboard");
      break;
    default:
      resetAfterSSOLogin();
      this.$router.replace("/Dashboard");
  }
}

export default {
  resetAfterSSOLogin,
  saveAfterSSOLoginSetting,
  getAfterSSOLoginSetting,
  processAction,
};
