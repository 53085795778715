import HcmPage from "@/layouts/page";

const viewFiles = require.context("@/views/modules", true, /index\.js$/);
const viewRoutes = viewFiles
  .keys()
  .map((k) => viewFiles(k).default)
  .sort((a, b) => a.sequence - b.sequence)
  .filter((rs) => {
    if (!rs.portalName) return false;
    let portalList = rs.portalName.split(",");

    if (portalList.length < 1) return false;
    return portalList.includes(process.env.VUE_APP_BUILD_PORTAL);
  })
  .map((module) => ({
    path: "/" + (module.path || module.name),
    parent: module.parent,
    name: module.name,
    component: module.component,
    props: module.props,
    children: module.children || [],
    meta: {
      title: module.title,
      sequence: module.sequence,
      isPageInternal: module.isPageInternal,
    },
  }));

const formPages = require.context("@/views/forms", true, /\.js$/);
const formRoutes = formPages
  .keys()
  .map((k) => formPages(k).default)
  .sort((a, b) => a.sequence - b.sequence)
  .map((page) => ({
    path: "/page" + page.name,
    name: page.name,
    component: HcmPage,
    parent: page.parent,
    meta: {
      formCode: page.formCode,
      title: page.title,
      sequence: page.sequence,
    },
  }));

export const routes = [...viewRoutes, ...formRoutes];
