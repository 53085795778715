<template>
    <div>
        <div class="card-body normal widget">
            <div class="widget-header">
                <div class="widget-title">{{ this.getRes("OpenTenderList") }}</div>
                <div class="widget-action"></div>
            </div>
            <div class="widget-body" style="overflow:auto">
                <div style="flex:1">
                    <el-table :data="tenderList" height="230">
                        <el-table-column
                            prop="ref"
                            :label="getRes('Tender.Ref')">
                        </el-table-column>
                        <el-table-column
                            prop="subject"
                            :label="getRes('Tender.Subject')">
                        </el-table-column>
                        <el-table-column
                            prop="issueDate"
                            :label="getRes('Tender.IssueDate')">
                        </el-table-column>
                        <el-table-column
                            prop="closingDate"
                            :label="getRes('Tender.ClosingDate')">
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'open-tender-list',
    data: function() {
        return {
            tenderList: [],
        };
    },
    mounted: function() {
        this.invokeService("Workflow", "GetDataByDataSource", ["GetOpenTenderList", []],
            function(msg) {
                if (msg.ReturnData.$.Success) {
                    var table = msg.ReturnData.$.ReturnData.toJson();
                    table.forEach(row => {
                        this.tenderList.push({ref: row.ref, subject: ((localStorage.getItem("Language") == "English") ? row.subjectEng : row.subjectChi), issueDate: this.formatDate(row.issueDate), closingDate: this.formatDateTime(row.closingDate)});
                    });
                }
            }
        );
    },
    methods: {
        formatDate: function(date) {
            return `${date.getFullYear()}/${(date.getMonth() + 1).toString().padStart(2, "0")}/${date.getDate().toString().padStart(2, "0")}`;
        },
        formatDateTime: function(date) {
            return `${date.getFullYear()}/${(date.getMonth() + 1).toString().padStart(2, "0")}/${date.getDate().toString().padStart(2, "0")} ${date.getHours().toString().padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}`;
        }
    }
}
</script>
