export function load(src) {
  const script = document.createElement("script");
  script.type = "text/javascript";
  script.src = src;
  document.body.appendChild(script);

  return new Promise((resolve) => {
    script.onload = resolve;
  });
}

export function compose(...promises) {
  return promises.reduce(
    (acc, curr) => {
      console.log(curr);
      return acc.then(curr);
    },
    Promise.resolve()
  );
}

const maps = {
  English: "en-US",
  Chinese: "zh-Hans",
  TraditionalChinese: "zh-Hant",
};

export function getLanguage() {
  return maps[localStorage.getItem("Language") || "English"];
}
