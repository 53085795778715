export default {
  path: "ViewUserForm/:mode/:code/:stepperId/:userId/:doiUserRole?",
  name: "DocumentViewUserForm", // Route name
  component: () => import("./viewForm.vue"),
  props: (route) => ({
    injectFormData: {
      userId: route.params.userId,
      tendererHcmId: route.params.userId,
      doiUserRole: route.params.doiUserRole,
    },
  }),
  title: "menu.ViewUserForm", // Form title, locale resource key, it SHOULD be same as menu rkey
  portalName: "procurer",
};
